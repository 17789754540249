import React, { useState } from 'react'
import sendRequest from './request.service'
import { ACTIVATE_ONLINE_CARD, CONFIRM_USER_EMAIL, CREATE_CONTACT, CREATE_STEP, DELETE_ACCOUNT, DELETE_PLAN, GET_CONTACTS, GET_PROFILE_INFO, GET_STEP, GET_USER_CARD_INFO, GET_USER_PLAN, HAS_CONTACT, IS_CONNECTED, IS_ONLINE, LOGIN, LOGOUT, REGISTER, RESEND_VALIDATION_CODE, RESET_PASSWORD, SEND_VERIFICATION_EMAIL, SETTLE_STEP, TOGGLE_ONLINE, UPDATE_PROFILE, UPDATE_PROFILE_INFO, VALIDATE_CONFIRMATION_EMAIL } from '../routes/routes'
import env from '../env'

const setLogin = (data,status) => {

    // Some action here when the use is not logged in

    if(status ===  true)
    {

        localStorage.setItem('vc_access_token',data.access_token)

        localStorage.setItem('vc_user',JSON.stringify(data.user))
        localStorage.setItem('vc_login',true)

    }
    else{

        localStorage.clear('vc_access_token')
        localStorage.clear('vc_user')
        localStorage.clear('vc_login',false)

    }

}

const authenticate = async (username,password) => {

    // 

    try {

        let data = {
            email:username,
            password:password
        }
    
        let response = await sendRequest.postData(data,LOGIN);
    
        if(response.status == 200)
        {
            if(response.data.user.activated == false && response.data.user.role == 'client')
            {
                // Then redirect
                let path = `${env.APP_URL}/mail-confirmation`

                // Send confirmation mail here

                let body = {user : response.data.user.id}

                let mailrq = await resendValidationCode(body)

                // alert(JSON.stringify(mailrq))

                if(mailrq)
                {
                    // Set local user id

                    localStorage.setItem('vc_user',response.data.user.id)

                    // Then redirect

                    return window.location.replace(path)
                }
                else{
                    return window.location.reload()
                }          

            }
            else{
                setLogin(response.data,true)
            }
        }
    
        return response.status == 200 ? true : false
        
    } catch (error) {
        alert(JSON.stringify(error.message))
    }

}

const resendValidationCode = async data => {

    let response = await sendRequest.postData(data,RESEND_VALIDATION_CODE)

    return response.status == 200 ? true : false

}

const logout = async () => {

    let response = await sendRequest.postData({},LOGOUT)

    return response.status == 200 ? true : false

}

const create_user = async (data) => {

    try {

        let response = await sendRequest.postData(data,REGISTER)

        // alert(JSON.stringify(response))

        if(response && response.status == 201)
        {
            // alert(response.data.user)
            localStorage.setItem('vc_user',response.data.user.id)
        }

        return response && response.status == 201 ? true : false
        
    } catch (error) {
        alert(error.message)
    }

}

const validateMail = async (data) => {

    let response = await sendRequest.postData(data,CONFIRM_USER_EMAIL)

    // alert(JSON.stringify(response))

    if(response.status == 200)
    {
        localStorage.setItem('vc_register_success',true)
    }

    return response.status == 200 ? true : false;

}

const passwordRecovery = () => {

}

const is_connected = async () => {

    let response = await sendRequest.getData(IS_CONNECTED)

    // alert(JSON.stringify(response))

    return response.status == 200 ? true : false

}

const client_has_confirmation = () => {

    return localStorage.getItem('vc_user') ? true : false

}

const is_admin = async () => {

    if(localStorage.getItem('vc_user'))
    {
        return JSON.parse(localStorage.getItem('vc_user')).role == 'admin' ? true : false
    }

}

const is_first_login = () => {

    let user = JSON.parse(localStorage.getItem('vc_user'))
    
    let firstLogin = user.address == null && user.phone == null && user.pic == null

    return firstLogin == true ? true : false

}

const updateProfile = async data => {

    let response = await sendRequest.postData(data,UPDATE_PROFILE,'multipart/form-data')

    // alert(JSON.stringify(response))

    if(response.status == 200)
    {
        localStorage.setItem('vc_user',JSON.stringify(response.data.user))
    }

    return response.status == 200 ? true : false

}

const getStep = async () => {

    let response = await sendRequest.getData(`${GET_STEP}/${JSON.parse(localStorage.getItem('vc_user')).id}`)

    // alert(JSON.stringify(response))

    return response.status == 200 ? response.data : undefined;

}

const createStep = async () => {

    let data = {
        user : JSON.parse(localStorage.getItem('vc_user')).id
    }

    let response = await sendRequest.postData(data,CREATE_STEP)

    return response.status == 200 ? true : false    

}

const settleStep = async () => {

    let response = await sendRequest.postData({},`${SETTLE_STEP}/${JSON.parse(localStorage.getItem('vc_user')).id}`)

    // alert(JSON.stringify(response))

    return response.status == 200 ? true : false    

}

const getUserPlan = async () => {

    let response = await sendRequest.getData(`${GET_USER_PLAN}/${JSON.parse(localStorage.getItem('vc_user')).id}`)

    // alert(JSON.stringify(response))

    return response.status == 200 ? response.data.plan : undefined

}

const getProfileInfo = async () => {

    let response = await sendRequest.getData(`${GET_PROFILE_INFO}/${JSON.parse(localStorage.getItem('vc_user')).id}`)

    // alert(JSON.stringify(response))

    return response.status == 200 ? response.data : undefined

}

const activateOnlineCard = async data => {

    let response = await sendRequest.postData(data,ACTIVATE_ONLINE_CARD)

    // alert(JSON.stringify(response))

    return response.status == 200 ? response.data : undefined

} 

const getUserCardInfo = async data => {

    let response = await sendRequest.postData(data,GET_USER_CARD_INFO)

    // alert(JSON.stringify(response))
    
    return response.status == 200 ? response.data : undefined

}

const sendVerificationEmail = async email => {

    let response = await sendRequest.postData({},`${SEND_VERIFICATION_EMAIL}/${email}`)

    // alert(JSON.stringify(response))

    return response.status == 200 ? true : false

}

const validateConfirmationEmail = async data => {

    let route = VALIDATE_CONFIRMATION_EMAIL

    let response = await sendRequest.postData(data,route)

    return response ? true : false

}

const resetPassword = async data => {

    let route = RESET_PASSWORD

    let response = await sendRequest.postData(data,route)

    return response.status == 200 ? true : false

}

const updateProfileInfo = async data => {

    let response = await sendRequest.postData(data,UPDATE_PROFILE_INFO)

    // alert(JSON.stringify(response))

    return response.status == 200 ? true : false

}

const deleteAccount = async data => {

    let response = await sendRequest.postData(data,DELETE_ACCOUNT)

    alert(JSON.stringify(response))

    return response.status == 200 ? true : false

}

const isOnline = async  () => {

    let response = await sendRequest.getData(IS_ONLINE)

    return response.status == 200 ? response.data.online : undefined

}

const toggleOnline = async data => {

    let response = await sendRequest.postData(data,TOGGLE_ONLINE)

    // alert(JSON.stringify(response))

    return response.status == 200 ? response.data.online : undefined

}

const getContacts = async () => {

    let response = await sendRequest.getData(`${GET_CONTACTS}/${JSON.parse(localStorage.getItem('vc_user')).id}`)

    // alert(JSON.stringify(response))

    return response.status == 200 ? response.data.contacts : undefined

}

const hasContact = async data => {

    let response = await sendRequest.postData(data,HAS_CONTACT)

    // alert(JSON.stringify(response))

    return response.status == 200 ? response.data.has : undefined

}

const addContact = async data => {

    let response = await sendRequest.postData(data,CREATE_CONTACT)

    return response.status == 200 ? true : false
    
}

const deletePlan = async data => {

    let response = await sendRequest.postData({},`${DELETE_PLAN}/${data.plan_id}`)

    // alert(JSON.stringify(response))

    return response.status == 200 ? true : false

}

export default {authenticate,logout,create_user,
    validateMail,passwordRecovery,is_connected,
    client_has_confirmation,is_admin,is_first_login,
    updateProfile,getStep,createStep,settleStep,
    getUserPlan,getProfileInfo,activateOnlineCard,
    getUserCardInfo,sendVerificationEmail,validateConfirmationEmail,
    resetPassword,updateProfileInfo,deleteAccount,isOnline,
    toggleOnline,getContacts,hasContact,addContact,deletePlan}