import React, { useEffect, useState } from "react";
import logo from "../../images/logo.png"
import { Link, Navigate } from "react-router-dom";
import titleService from "../../services/title.service";
import usersService from "../../services/users.service";
import GlobalLoader from "../../components/Spinners/GlobalLoader";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import env from "../../env";

export default function Login()
{

    const [email,setEmail] = useState(null)

    const [password,setPassword] = useState(null)

    const [loading,setLoading] = useState(false)

    const [redirectAdmin,setRedirectAdmin] = useState(false)

    const [redirectCustomer,setRedirectCustomer] = useState(false)

    const handleSubmit = async e => {

        e.preventDefault()

        setLoading(true)

        if(email == null || password == null)
        {

            toast.error('Veuillez remplir tous les champs')
            
        }
        else{

            let response = await usersService.authenticate(email,password)

            if(response == true)
            {
                // Check the user's role and redirect him accordingly
                if(JSON.parse(localStorage.getItem('vc_user')).role == 'client')
                {
                    return window.location.replace(`${env.APP_URL}/client`)
                }
                else{
                    return window.location.replace(`${env.APP_URL}/admin`)
                }
            }
            else{
                toast.error('Identifiants incorrects')
            }

        }

        setLoading(false)

    }

    useEffect(() => {

        titleService.loginTitle()

        if(localStorage.getItem('vc_register_success'))
        {
            localStorage.removeItem('vc_register_success')
        }

        if(localStorage.getItem('vc_should_change_password'))
        {
            localStorage.removeItem('vc_should_change_password')
        }

    },[])

    return (

        <React.Fragment>

        <section className="bg-[#3063a3] h-screen w-screen flex justify-center items-center">

            {loading && <GlobalLoader/>}

            {redirectAdmin && <Navigate to="/admin"/>}

            {redirectCustomer && <Navigate to="/client"/>}
            
            <form onSubmit={handleSubmit} className="bg-white text-white lg:w-4/12 md:w-6/12 lg:p-6 md:p-6 sm:w-7/12 sm:p-4 w-11/12 p-5">

                {loading && <GlobalLoader/>}

                <div class="flex justify-center mb-8">
                    <img class="h-10" src={logo} alt=""/>
                </div>

                <div className="pb-4">
                    <label htmlFor="username" className="block py-4 text-black">Votre email</label>
                    <input type="email" className="bg-gray-100 p-2 w-full text-black" id="username" onChange={(e) => setEmail(e.target.value)} placeholder="Votre email"/>
                </div>

                <div className="pb-4">
                    <div class="flex items-center justify-between mb-2 text-black">
                        <label for="password">Mot de passe</label>
                        <Link to="/mot-de-passe-oublie" class="text-sm text-[#3063a3]">Mot de passe oublié ?</Link>
                    </div>
                    {/* <label htmlFor="password" className="block py-4">Mot de passe</label> */}
                    <input type="password" id="password" className="bg-gray-100 p-2 w-full text-black" onChange={(e) => setPassword(e.target.value)}  placeholder="Votre mot de passe"/>
                </div>

                <div>
                    <button type="submit" className="bg-black p-2 inline-block w-full">Connexion</button>
                </div>

                <p class="text-black mt-4">Vous avez un compte ?<Link to="/inscription" class="font-medium ms-1 underline">S'inscrire</Link></p>

            </form>

            <ToastContainer/>

        </section>

        {/* <section class="bg-[#3063a3] h-screen w-screen flex justify-center items-center">

            {loading && <GlobalLoader/>}

            {redirectAdmin && <Navigate to="/admin"/>}

            {redirectCustomer && <Navigate to="/client"/>}

            <div class="2xl:w-1/4 lg:w-1/3 md:w-1/2 w-full">
                <div class="card overflow-hidden sm:rounded-md rounded-none">
                    <div class="px-6 py-8">
                        <a href="index.html" class="flex justify-center mb-8">
                            <img class="h-10" src={logo} alt=""/>
                        </a>

                        <form onSubmit={handleSubmit}>

                            <div class="mb-4">
                                <label class="mb-2" for="LoggingEmailAddress">Adresse email</label>
                                <input onInput={e => setEmail(e.target.value)} id="LoggingEmailAddress" class="form-input" type="email" placeholder="Adresse email"/>
                            </div>

                            <div class="mb-4">
                                <div class="flex items-center justify-between mb-2">
                                    <label for="loggingPassword">Mot de passe</label>
                                    <Link to="/mot-de-passe-oublie" class="text-sm text-[#3063a3]">Mot de passe oublié ?</Link>
                                </div>
                                <input onInput={e => setPassword(e.target.value)} id="loggingPassword" class="form-input" type="password" placeholder="Votre mot de passe"/>
                            </div>

                            <div class="flex justify-center mb-3">
                                <button class="btn w-full text-white bg-[#030e16]"> Se connecter </button>
                            </div>

                        </form>

                    </div>
                </div>

                <p class="text-white text-center mt-8">Créer un compte ?<Link to="/inscription" class="font-medium ms-1">S'inscrire</Link></p>
            </div>
            <ToastContainer/>
        </section> */}

        </React.Fragment>



    )

}