import React, { useEffect, useState } from "react";
import logo from "../../images/logo.png"
import GlobalLoader from "../../components/Spinners/GlobalLoader";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Link, Navigate } from "react-router-dom";
import env from "../../env";
import usersService from "../../services/users.service";

export default function EmailVerification()
{

    const [code,setCode] = useState(null)

    const [loading,setLoading] = useState(false)

    const [shouldRedirectToPasswordChange,setShouldRedirectToPasswordChange] = useState(false)

    const handleSubmit = async e => {

        e.preventDefault()

        setLoading(true)

        let data = {
            email : localStorage.getItem('vc_recuperation_email'),
            code : code
        }

        let response = await usersService.validateConfirmationEmail(data)

        if(response)
        {

            // Set localStorage vc_should_change_password

            localStorage.setItem('vc_should_change_password',true)
            setShouldRedirectToPasswordChange(true)

        }
        else{
            toast.error('Code incorrect')
        }

        setLoading(false)

    }

    useEffect(() => {

        if(!localStorage.getItem('vc_recuperation_email'))
        {
            return window.location.replace(`${env.APP_URL}`)
        }

    },[])

    return (
        
        <>

            <section className="bg-[#3063a3] h-screen w-screen flex justify-center items-center">

                {shouldRedirectToPasswordChange && <Navigate to="/password-modification"/>}

                <form onSubmit={handleSubmit} className="bg-white text-white lg:w-4/12 md:w-6/12 lg:p-6 md:p-6 sm:w-7/12 sm:p-4 w-11/12 p-5">

                    {loading && <GlobalLoader/>}

                    <div class="flex justify-center mb-8">
                        <img class="h-10" src={logo} alt=""/>
                    </div>

                    <p class="text-center lg:w-3/4 mx-auto mb-6 text-black">Entrez le code que nous vous avons envoyé par email</p>

                    <div className="pb-4">
                        <label htmlFor="code" className="block py-4 text-black">Code de confirmation</label>
                        <input type="text" className="bg-gray-100 p-2 w-full text-black" id="code" onInput={(e) => setCode(e.target.value)} placeholder="Code de confirmation"/>
                    </div>

                    <div>
                        <button type="submit" className="bg-black p-2 inline-block w-full">valider</button>
                    </div>

                    <p class="text-black mt-4">Vous avez un compte ?<Link to="/inscription" class="font-medium ms-1 underline">S'inscrire</Link></p>

                </form>

                <ToastContainer/>

            </section>

        </>
    
    )

}