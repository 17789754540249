import React from "react";

const matchPermissions = key => {

    let result = null

    switch (key) {
        case 'Name':
            result = 'Nom'
            break;
        
        case 'Surname' :

            result = 'Prénom'
            break

        case 'Email':
            result = 'Adresse email'
            break

        case 'address':
            result = 'Adresse personnelle'
            break

        case 'Phone':
            result = 'Numéro de téléphone'
            break

        case 'Pic':
            result = 'Photo de profile'
            break

        case 'Venture':
            result = 'Votre entreprise'
            break

        case 'Position in':
            result = 'Votre poste'
            break

        case 'Phone pro 1':
            result = 'Numéro professionnel 1'
            break

        case 'Phone pro 2':
            result = 'Numéro professionnel 2'
            break

        case 'Venture address':
            result = 'Adresse de votre entreprise'
            break

        case 'Mail pro':
            result = 'Adresse email professionnelle'
            break

        case 'Website':
            result = 'Site web'
            break

        case 'Socials':
            result = 'Réseaux sociaux (Facebook,Twitter,YouTube,Instagram,LinkedIn,Vimeo,Pinterest)'
            break
    
        default:
            result = 'Hey'
            break;
    }

    return result

}

export default {matchPermissions}