import React, { useContext, useEffect, useState } from "react";
import usersService from "../../../services/users.service";
import { Navigate } from "react-router-dom";
import GlobalLoader from "../../../components/Spinners/GlobalLoader";
import { ShouldRFLContext } from "../../../components/LayoutCustomer";
import env from "../../../env";

export default function FirstLogin()
{

    const [connected,setConnected] = useState(null)

    const [loading,setLoading] = useState(false)

    const [address,setAddress] = useState(null)

    const [phone,setPhone] = useState(null)

    const [pic,setPic] = useState(null)

    const [shouldRedirectToIndex,setShouldRedirectToIndex] = useState(false)

    const [shouldRedirectHome,setShouldRedirectHome] = useState(false)

    const {shouldRedirectFirstLogin,setShouldRedirectFirstLogin} = useContext(ShouldRFLContext)

    const updateProfile = async e => {

        e.preventDefault()

        setLoading(true)

        let data = {
            address : address,
            pic : pic,
            phone : phone,
            user : JSON.parse(localStorage.getItem('vc_user')).id
        }

        let response = await usersService.updateProfile(data)

        if(response)
        {
            let res = await usersService.createStep()
            if(res)
            {

                setShouldRedirectFirstLogin(false)
                // setShouldRedirectToIndex(true)
                window.location.replace(env.APP_URL+'/client')       
                
            }
            else{
                alert('Step service error')
            }
        }
        else{
            console.log('Something went wrong')
        }

        setLoading(false)

    }

    useEffect(() => {

        usersService.is_connected()
        .then(response => setConnected(response))

        const firstLogin = () => {

            let res = usersService.is_first_login()

            return res

        }

        if(firstLogin() == false)
        {
            // Then instruct to redirect to profile update route
            setShouldRedirectHome(true)
        }

    },[])

    return (

        <React.Fragment>

            {
                connected == false ? <Navigate to="/"/> : 

                <section className="min-h-[500px] px-4">

                    {/* {shouldRedirectToIndex && <Navigate to="/client"/>} */}

                    {shouldRedirectHome && <Navigate to="/client"/>}

                    {loading && <GlobalLoader/>}

                    <header className="text-lg font-extrabold mb-7 text-black mt-10">
                        Veuillez remplir votre profile
                    </header>

                    <form onSubmit={updateProfile}>

                        <div class="mb-4">
                            <label class="mb-2" for="LoggingEmailAddress">Votre adresse complète</label>
                            <input required onInput={e => setAddress(e.target.value)} id="LoggingEmailAddress" class="w-full block my-2 placeholder:text-gray-700" type="text" placeholder="Votre adresse complète"/>
                        </div>

                        <div class="mb-4">
                            <label class="mb-2" for="LoggingEmailAddress">Votre numéro de téléphone</label>
                            <input required onInput={e => setPhone(e.target.value)} id="LoggingEmailAddress" class="w-full block my-2 placeholder:text-gray-700" type="text" placeholder="Votre numéro de téléphone"/>
                        </div>

                        <div class="mb-4">
                            <label class="mb-2" for="LoggingEmailAddress">Photo de profile</label>
                            <input required onInput={e => setPic(e.target.files[0])} id="LoggingEmailAddress" class="w-full block my-2 border border-slate-900 p-2" type="file"/>
                        </div>

                        <div class="flex justify-center mb-3">
                            <button class="btn w-full text-white bg-[#030e16] p-2"> Envoyer </button>
                        </div>

                    </form>

                </section>

            }

        </React.Fragment>

    )

}