import React from "react";
import "../../stylesheets/loader.css"

export default function GlobalLoader()
{

    return (

        <section className="fixed inset-0 bg-gray-700 bg-opacity-75 z-50 flex items-center justify-center">

            <span class="loader"></span>

        </section>

    )

}