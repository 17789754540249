import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/auth/Login";
import SignUp from "../pages/auth/Signup";
import MailConfirmation from "../pages/auth/MailConfirmation";
import LayoutAdmin from "./LayoutAdmin";
import IndexAdmin from "../pages/dashboard/admin";
import LayoutCustomer from "./LayoutCustomer";
import IndexCustomer from "../pages/dashboard/customer";
import PasswordRecovery from "../pages/auth/PasswordRecovery";
import NotFound404 from "./404";
import Customers from "../pages/dashboard/admin/Customers";
import Plans from "../pages/dashboard/admin/Plans";
import Payments from "../pages/dashboard/admin/Payments";
import Settings from "../pages/dashboard/admin/Settings";
import RegisterSuccess from "../pages/auth/RegisterSuccess";
import Networks from "../pages/dashboard/admin/Network";
import FirstLogin from "../pages/dashboard/customer/FirstLogin";
import ChoosePlan from "../pages/dashboard/customer/ChoosePlan";
import PaymentMethods from "../pages/dashboard/customer/PaymentMethods";
import FillPlanInfo from "../pages/dashboard/customer/FillPlanInfo";
import PaymentSuccess from "../pages/dashboard/customer/PaymentSuccess";
import PaymentFails from "../pages/dashboard/customer/PaymentFails";
import CardLayout from "../pages/dashboard/customer/CardLayout";
import EmailVerification from "../pages/auth/EmailVerification";
import PasswordModification from "../pages/auth/PasswordModification";
import Profile from "../pages/dashboard/customer/Profile";
import Users from "../pages/dashboard/admin/Users";
import Contacts from "../pages/dashboard/customer/Contacts";

export default function Navigator()
{

    return (

        <BrowserRouter>

            <Routes>

                <Route path="/admin" element={<LayoutAdmin/>}>

                    <Route path="/admin" element={<IndexAdmin/>}></Route>
                    <Route path="/admin/clients" element={<Customers/>}></Route>
                    <Route path="/admin/plans" element={<Plans/>}></Route>
                    <Route path="/admin/media" element={<Networks/>}></Route>
                    <Route path="/admin/paiements" element={<Payments/>}></Route>
                    <Route path="/admin/utilisateurs" element={<Users/>}></Route>
                    <Route path="/admin/paramètres" element={<Settings/>}></Route>

                </Route>

                <Route path="/client" element={<LayoutCustomer/>}>

                    <Route path="/client" element={<IndexCustomer/>}></Route>

                    <Route path="/client/profile" element={<Profile/>}></Route>

                    <Route path="/client/contacts" element={<Contacts/>}></Route>

                    <Route path="/client/remplir-profile" element={<FirstLogin/>}></Route>

                    <Route path="/client/choisir-plan" element={<ChoosePlan/>}></Route>

                    <Route path="/client/info-a-afficher" element={<FillPlanInfo/>}></Route>

                </Route>

                <Route path="/methode-de-paiement" element={<PaymentMethods/>}></Route>

                <Route path="/payment/success" element={<PaymentSuccess/>}></Route>

                <Route path="/payment/fails" element={<PaymentFails/>}></Route>

                <Route path="/carte/:ucode" element={<CardLayout/>}></Route>

                <Route path="/" element={<Login/>}></Route>

                <Route path="/inscription" element={<SignUp/>}></Route>

                <Route path="/mail-confirmation" element={<MailConfirmation/>}></Route>

                <Route path="/mot-de-passe-oublie" element={<PasswordRecovery/>}></Route>

                <Route path="/inscription-success" element={<RegisterSuccess/>}></Route>

                <Route path="/verification-email" element={<EmailVerification/>}></Route>

                <Route path="/password-modification" element={<PasswordModification/>}></Route>

                <Route path="*" element={<NotFound404/>}></Route>

            </Routes>
            
        </BrowserRouter>

    )

}