import React, { useEffect, useState } from "react";
import logo from "../../images/logo.png"
import { Link, Navigate } from "react-router-dom";
import GlobalLoader from "../../components/Spinners/GlobalLoader";
import usersService from "../../services/users.service";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function MailConfirmation()
{

    const [user,setUser] = useState(localStorage.getItem('vc_user') ? localStorage.getItem('vc_user') : null)

    const [code,setCode] = useState(null)

    const [loading,setLoading] = useState(false)

    const [noConfirmationRedirect,setNoConfirmationRedirect] = useState(false)

    const [shouldNavigateToSuccess,setShouldNavigateToSuccess] = useState(false)

    const handleSubmit = async e =>  {

        e.preventDefault()

        setLoading(true)

        let data  = {user:user,code:code}

        let response = await usersService.validateMail(data)

        if(response == true)
        {
            toast.success('Adresse email validée avec succès')
            setShouldNavigateToSuccess(true)
        }

        setLoading(false)

    }

    useEffect(() => {

        if(!usersService.client_has_confirmation())
        {
            setNoConfirmationRedirect(true)
        }

    },[])

    return (
        
        <>

            <section className="bg-[#3063a3] h-screen w-screen flex justify-center items-center">

                {shouldNavigateToSuccess && <Navigate to='/inscription-success'/>}

                {noConfirmationRedirect == true && <Navigate to='/'/>}

                {loading && <GlobalLoader/>}

                <form onSubmit={handleSubmit} className="bg-white text-white lg:w-4/12 md:w-6/12 lg:p-6 md:p-6 sm:w-7/12 sm:p-4 w-11/12 p-5">

                    {loading && <GlobalLoader/>}

                    <div class="flex justify-center mb-8">
                        <img class="h-10" src={logo} alt=""/>
                    </div>

                    <p class="text-center lg:w-3/4 mx-auto mb-6 text-black">Entrez le code que nous vous avons envoyé par email pour valider la création de votre compte</p>

                    <div className="pb-4">
                        <label htmlFor="code" className="block py-4 text-black">Code de confirmation</label>
                        <input type="text" className="bg-gray-100 p-2 w-full text-black" id="code" onInput={(e) => setCode(e.target.value)} placeholder="Code de confirmation"/>
                    </div>

                    <div>
                        <button type="submit" className="bg-black p-2 inline-block w-full">valider</button>
                    </div>

                    <p class="text-black mt-4">Vous avez un compte ?<Link to="/inscription" class="font-medium ms-1 underline">S'inscrire</Link></p>

                </form>

                <ToastContainer/>

            </section>

            {/* <section class="bg-[#3063a3] h-screen w-screen flex justify-center items-center">

                {shouldNavigateToSuccess && <Navigate to='/inscription-success'/>}

                {noConfirmationRedirect == true && <Navigate to='/'/>}

                {loading && <GlobalLoader/>}

                <div class="2xl:w-1/4 lg:w-1/3 md:w-1/2 w-full">
                    <div class="card overflow-hidden sm:rounded-md rounded-none">
                        <div class="px-6 py-8">
                            <a href="index.html" class="flex justify-center mb-8">
                                <img class="h-10" src={logo} alt=""/>
                            </a>

                            <p class="text-center lg:w-3/4 mx-auto mb-6">Entrez le code que nous vous avons envoyé par email pour valider la création de votre compte</p>

                            <form onSubmit={handleSubmit}>

                                <div class="mb-4">
                                    <label class="mb-2" for="LoggingEmailAddress">Code de confirmation</label>
                                    <input id="LoggingEmailAddress" class="form-input" onInput={e => setCode(e.target.value)} type="text" placeholder="Code de confirmation"/>
                                </div>

                                <div class="flex justify-center mb-3">
                                    <button class="btn w-full text-white bg-[#030e16]"> Valider </button>
                                </div>

                            </form>

                        </div>
                    </div>

                    <p class="text-white text-center mt-8">Avez vous un compte ?<Link to="/" class="font-medium ms-1">Se connecter</Link></p>
                </div>
                <ToastContainer/>
            </section> */}

        </>

    )
}