import React from "react";
import sendRequest from "./request.service";
import { CREATE_STRIPE_PAYMENT } from "../routes/routes";

const createStripePayment = async data => {

    let response = await sendRequest.postData(data,CREATE_STRIPE_PAYMENT)

    // alert(JSON.stringify(response))

    return response.status == 200 ? response.data.url : undefined

}

export default {createStripePayment}