
// phone

// const API_BASE = 'http://192.168.8.100:8000/api/v0/'

// const APP_URL = "http://192.168.8.100:5000"

// export default {API_BASE,APP_URL}

// Local on PC

// const API_BASE = 'http://localhost:8000/api/v0/'

// const APP_URL = "http://localhost:5000"

// export default {API_BASE,APP_URL}

// Online


const API_BASE = 'https://backend.shinneroutfit.com/api/v0/'

const APP_URL = "https://app.cartevirtuelle.shop"

export default {API_BASE,APP_URL}