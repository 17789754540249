import React, { useEffect, useState } from "react";
import logo from "../../images/logo.png"
import { Link } from "react-router-dom";
import env from "../../env";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usersService from "../../services/users.service";
import GlobalLoader from "../../components/Spinners/GlobalLoader";

export default function PasswordModification()
{

    const [password,setPassword] = useState(null)

    const [verification,setVerification] = useState(null)

    const [loading,setLoading] = useState(false)

    const handleSubmit = async e => {

        e.preventDefault()

        setLoading(true)

        if(password == null || verification == null)
        {
            toast.error('Veuillez remplir tous les champs')
        }
        else if(password != verification)
        {
            toast.error('Mots de passes inconformes')
        }
        else{

            let data = {
                email : localStorage.getItem('vc_recuperation_email'),
                password : password
            }

            let response = await usersService.resetPassword(data)

            if(response)
            {

                if(localStorage.getItem('vc_recuperation_email'))
                {
                    localStorage.removeItem('vc_recuperation_email')
                }

                toast.success('Mot de passe changé')

                setTimeout(() => {

                    return window.location.replace(env.APP_URL)
                    
                }, 2000);

            }
            else{
                toast.error("Erreur serveur")
            }

        }

        setLoading(false)

    }

    useEffect(() => {

        if(!localStorage.getItem('vc_should_change_password'))
        {
            return window.location.replace(`${env.APP_URL}`)
        }

    },[])

    return (

        <>

            <section className="bg-[#3063a3] h-screen w-screen flex justify-center items-center">
                
                <form onSubmit={handleSubmit} className="bg-white text-white lg:w-4/12 md:w-6/12 lg:p-6 md:p-6 sm:w-7/12 sm:p-4 w-11/12 p-5">

                    {loading && <GlobalLoader/>}

                    <div class="flex justify-center mb-8">
                        <img class="h-10" src={logo} alt=""/>
                    </div>

                    <div class="mb-4">
                        <label class="mb-2" for="loggingPassword">Nouveau mot de passe</label>
                        <input required onChange={e => setPassword(e.target.value)} id="loggingPassword" class="bg-gray-100 p-2 w-full text-black" type="password" placeholder="Choisissez un mot de passe"/>
                    </div>

                    <div class="mb-4">
                        <label class="mb-2" for="loggingPassword">Confirmation du mot de passe</label>
                        <input required onChange={e => setVerification(e.target.value)} id="loggingPassword" class="bg-gray-100 p-2 w-full text-black" type="password" placeholder="Choisissez un mot de passe"/>
                    </div>

                    <div>
                        <button type="submit" className="bg-black p-2 inline-block w-full">Valider</button>
                    </div>

                    <p class="text-black text-center mt-8">Avez vous un compte ?<Link to="/" class="font-medium ms-1 underline text-blue-600">Se connecter</Link></p>

                </form>

                <ToastContainer/>

            </section>

        </>

    )

}