import React, { useEffect, useState } from "react";
import adminService from "../../../services/admin.service";
import titleService from "../../../services/title.service";
import { CurrencyDollarIcon, CurrencyEuroIcon } from "@heroicons/react/24/outline";
import OnpageLoader from "../../../components/Spinners/OnpageLoader";

export default function Payments()
{

    const [transactions,setTransactions] = useState([])

    const [loading,setLoading] = useState(true)

    const get_latest_transactions = async () => {

        setLoading(true)

        let response = await adminService.paymentSummary()

        if(response == undefined)
        {
            setLoading(false)
            alert('Payment service error')
        }
        else{
            setLoading(false)
            setTransactions(response.payments)
        }

    }

    useEffect(() => {

        get_latest_transactions()

        titleService.paymentTitle()

    },[])

    return (
        <>

                <div className="grid xl:grid-cols-1 gap-6 min-h-[500px]">

                    <div className="card overflow-hidden">

                        <div className="flex items-center mb-6 p-5 text-slate-400 shadow-current shadow-lg">
                            <CurrencyEuroIcon className="h-6 text-slate-900"/>
                            <h4 className="text-slate-900 text-lg font-bold ml-2">Transactions</h4>
                        </div>

                        {loading ? <OnpageLoader/> : 
                        
                            <div className="overflow-x-auto">
                                <div className="min-w-full inline-block align-middle whitespace-nowrap">
                                    <div className="overflow-hidden">
                                        <table className="min-w-full">
                                            <thead className="bg-light/40 border-b border-gray-200">
                                                <tr>
                                                    <th className="px-6 py-3 text-start">Plan</th>
                                                    <th className="px-6 py-3 text-start">Utilisateur</th>
                                                    <th className="px-6 py-3 text-start">Système de paiement</th>
                                                    <th className="px-6 py-3 text-start">Montant</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    transactions.length > 0 ? 

                                                    transactions.map(transaction => (

                                                        <tr className="border-b border-gray-200">
                                                            <td className="px-6 py-6">{transaction.plan.name}</td>
                                                            <td className="px-6 py-6">{transaction.user.name}</td>
                                                            <td className="px-6 py-6">
                                                                <span className="px-2 py-1 bg-success/10 text-success text-xs rounded">Stripe</span>
                                                            </td>
                                                            <td className="px-6 py-6">€{transaction.amount}</td>
                                                        </tr>

                                                    ))

                                                    : 

                                                    <tr className="border-b border-gray-200">
                                                        <td className="px-6 py-3">Aucune transaction pour le moment</td>
                                                    </tr>

                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        
                        }

                    </div>

                </div>

        </>
    )

}