import React, { useContext, useEffect, useState } from "react";
import adminService from "../../../services/admin.service";
import GlobalLoader from "../../../components/Spinners/GlobalLoader";
import { Navigate } from "react-router-dom";
import paymentService from "../../../services/payment.service";
import usersService from "../../../services/users.service";
import { StepContext } from "../../../components/LayoutCustomer";
import matcherService from "../../../services/matcher.service";
import OnpageLoader from "../../../components/Spinners/OnpageLoader";

export default function ChoosePlan()
{

    const [plans,setPlans] = useState([])

    const [loading,setLoading] = useState(false)

    const [isLoading,setIsLoading] = useState(true)

    const [shouldRedirectToPayment,setShouldRedirecToPayment] = useState(false)

    const {step,setStep} = useContext(StepContext)

    const get_plans = async () => {

        let response = await adminService.getPlans()

        if(response == undefined)
        {
            console.log('Something went wrong trying to fetch plans')
        }
        else{
            setPlans(response)
        }

        setIsLoading(false)

    }

    const handlePlanSelect = async e => {

        let plan = plans.filter(plan => plan.id == e.target.getAttribute('data'))[0]

        setLoading(true)

        let data = {
            user : JSON.parse(localStorage.getItem('vc_user')).id,
            product : plan.name,
            amount : plan.price,
            plan : plan.id
        }

        let settled = await usersService.settleStep()

        if(settled)
        {

            usersService.getStep()
            .then(response => {

                if(response == undefined)
                {
                    console.log('Something went wrong fetching steps')
                }
                else{

                    localStorage.setItem('payment_track',true)

                    localStorage.setItem('vc_payment_data',JSON.stringify(data))

                    // console.log(JSON.stringify(response))

                    setStep(response.step)
        
                    return window.location.replace('/methode-de-paiement')
                    // console.log(JSON.stringify(step))
                }

            })

        }
        else{
            console.log('Error while settling step')
        }

        setLoading(false)

    }

    useEffect(() => {

        get_plans()

    },[])

    return (

        <section className="px-4 min-h-[60vh]">

            {loading && <GlobalLoader/>}

            {/* {shouldRedirectToPayment && <Navigate to="/client/methode-de-paiement"/>} */}

            <header className="text-2xl font-extrabold">Choisissez le plan qui vous convient</header>

            {
                isLoading ? <OnpageLoader/> : 
                <article className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 pt-10">

                    {
                        plans.map(plan => (

                            <div className="border border-gray-400 p-4 relative min-h-[480px]">

                                <div className="font-extrabold text-lg mb-3">{plan.name} (€{plan.price})</div>

                                <hr />

                                <div className="">

                                    <ul className="list-disc list-inside py-5">
                                    {
                                        plan.rights.map(right => (
                                            <li className="text-sm break-words">{matcherService.matchPermissions(right.permission.name)}</li>
                                        ))
                                    }
                                    </ul>

                                </div>

                                <div class="flex justify-center mb-3 absolute w-11/12 left-0 right-0 bottom-0.5 mx-auto">
                                    <button class="w-full text-sm text-white bg-[#030e16] font-extrabold sm:p-1 p-2" data={plan.id} onClick={handlePlanSelect}> Choisir ce plan </button>
                                </div>

                            </div>

                        ))
                    }

                </article>
            }

        </section>

    )

}