import React from "react";
import sendRequest from "./request.service";
import { CREATE_MEDIA, CREATE_PLAN, DELETE_MEDIA, GET_MEDIA, GET_PLANS, GET_RIGHTS, GET_USERS, ONLINE_PROFILES_COUNT, PAYMENT_SUMMARY, REMOVE_RIGHT, SAVE_PLAN_PERMISSIONS, USER_COUNT } from "../routes/routes";

const userCount = async () => {

    let response = await sendRequest.getData(USER_COUNT)

    return response.status == 200 ? response.data.count : undefined;

}

const onlineProfilesCount = async () => {

    let response = await sendRequest.getData(ONLINE_PROFILES_COUNT)

    // alert(JSON.stringify(response.status))

    return response.status == 200 ? response.data.count : undefined;
    
}

const paymentSummary = async () => {

    let response = await sendRequest.getData(PAYMENT_SUMMARY)

    return response.status == 200 ? response.data : undefined;

}

const getPlans = async () => {

    let response = await sendRequest.getData(GET_PLANS)

    // alert(JSON.stringify(response))

    return response.status == 200 ? response.data.plans : undefined

}

const get_rights = async () => {

    let response = await sendRequest.getData(GET_RIGHTS)

    return response.status == 200 ? response.data.rights : undefined

}

const createPlan = async (data) => {

    let response = await sendRequest.postData(data,CREATE_PLAN)

    // alert(JSON.stringify(response))

    return response.status == 200 ? response.data : undefined;

}

const savePlanPermissions = async data => {

    let response = await sendRequest.postData(data,SAVE_PLAN_PERMISSIONS)

    // alert(JSON.stringify(response))

    return response.status == 200 ? response.data : undefined

}

const removeRight = async data => {

    let response = await sendRequest.postData(data,REMOVE_RIGHT)

    return response.status == 200 ? response.data : undefined

}

const getMedia = async () => {

    let response = await sendRequest.getData(GET_MEDIA)

    return response.status == 200 ? response.data.media : undefined

}

const createMedia = async data => {

    let response = await sendRequest.postData(data,CREATE_MEDIA,'multipart/form-data')

    // alert(JSON.stringify(response))

    return response.status == 200 ? response.data.media : undefined

}

const deleteMedia = async data => {

    let response = await sendRequest.postData(data,DELETE_MEDIA)

    return response.status == 200 ? response.data : undefined

}

const getUsers = async () => {

    let response = await sendRequest.getData(GET_USERS)

    return response.status == 200 ? response.data.users : undefined

}

export default {userCount,onlineProfilesCount,paymentSummary,
    getPlans,createPlan,get_rights,savePlanPermissions,
    removeRight,getMedia,createMedia,deleteMedia,getUsers}