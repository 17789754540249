import React, { useState } from "react";
import GlobalLoader from "../../../../components/Spinners/GlobalLoader";
import flogo from "../../../../images/icons8-facebook-48.png"
import inslogo from "../../../../images/icons8-instagram-48.png"
import linklogo from "../../../../images/icons8-linkedin-48.png"
import youlogo from "../../../../images/icons8-youtube-48.png"
import vimlogo from "../../../../images/icons8-vimeo-video-platform-free-video-viewing-services-platform-48.png"
import tiklogo from "../../../../images/icons8-tic-tac-48.png"
import twlogo from "../../../../images/icons8-twitter-48.png"
import pinlogo from "../../../../images/icons8-pinterest-48.png"
import usersService from "../../../../services/users.service";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Bars3Icon, BellIcon, GlobeAltIcon } from '@heroicons/react/24/outline'

import env from "../../../../env";

export default function FillForSecond()
{

    const [loading,setLoading] = useState(false)

    const [website,setWebsite] = useState(null)

    const [facebook,setFacebook] = useState(null)

    const [instagram,setInstagram] = useState(null)

    const [twitter,setTwitter] = useState(null)

    const [tiktok,setTiktok] = useState(null)

    const [vimeo,setVimeo] = useState(null)

    const [linkedin,setLinkedIn] = useState(null)

    const [youtube,setYouTube] = useState(null)

    const [pinterest,setPinterest] = useState(null)

    const handleSubmit = async e => {

        e.preventDefault()

        setLoading(true)

        let data = {
            user : JSON.parse(localStorage.getItem('vc_user')).id,
            website : website,
            facebook : facebook,
            linkedin : linkedin,
            youtube : youtube,
            twitter : twitter,
            tiktok : tiktok,
            vimeo : vimeo,
            pinterest : pinterest,
            instagram : instagram
        }

        // alert(JSON.stringify(data))

        let response = await usersService.activateOnlineCard(data)

        if(response == undefined)
        {
            toast.error('Service error')
        }
        else{
            return window.location.replace(`${env.APP_URL}/client`)
        }

        setLoading(false)

    }

    return (
        <React.Fragment>

            <section className="px-4">

                {loading && <GlobalLoader/>}

                <ToastContainer/>

                <header className="text-lg font-extrabold mb-7 text-black">
                    Vérifiez et remplissez vos informations avant la mise en ligne de votre carte virtuelle
                </header>

                <form onSubmit={handleSubmit}>

                    <header className="">Lien vers votre site web</header>

                    <div class="flex my-5">
                        <div class="flex cursor-pointer items-center justify-center border border-[#bcc2c9] bg-[#ebf2fa] px-3 font-semibold ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md">
                            <GlobeAltIcon className="h-6"/>
                        </div>
                        <input required id="carte_field" type="text" onInput={e => setWebsite(e.target.value)} placeholder="Lien vers votre site web" class="ltr:rounded-l-none focus:outline-none focus:border-none w-full rtl:rounded-r-none"/>
                    </div>

                    <header className="">Vos réseaux sociaux</header>

                    <div class="flex my-2">
                        <div class="flex cursor-pointer items-center justify-center border border-[#bcc2c9] bg-[#ebf2fa] px-3 font-semibold ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md">
                            <img src={flogo} alt="" className="h-6"/>
                        </div>
                        <input required id="carte_field" type="text" onInput={e => setFacebook(e.target.value)} placeholder="Lien vers votre compte Facebook" class="ltr:rounded-l-none focus:outline-none focus:border-none w-full rtl:rounded-r-none"/>
                    </div>

                    <div class="flex my-2">
                        <div class="flex cursor-pointer items-center justify-center border border-[#bcc2c9] bg-[#ebf2fa] px-3 font-semibold ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md">
                            <img src={inslogo} alt="" className="h-6"/>
                        </div>
                        <input required id="carte_field" type="text" onInput={e => setInstagram(e.target.value)} placeholder="Lien vers votre compte Instagram" class="ltr:rounded-l-none focus:outline-none focus:border-none w-full rtl:rounded-r-none"/>
                    </div>

                    <div class="flex my-2">
                        <div class="flex cursor-pointer items-center justify-center border border-[#bcc2c9] bg-[#ebf2fa] px-3 font-semibold ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md">
                            <img src={twlogo} alt="" className="h-6"/>
                        </div>
                        <input required id="carte_field" type="text" onInput={e => setTwitter(e.target.value)} placeholder="Lien vers votre compte Twitter" class="ltr:rounded-l-none focus:outline-none focus:border-none w-full rtl:rounded-r-none"/>
                    </div>

                    <div class="flex my-2">
                        <div class="flex cursor-pointer items-center justify-center border border-[#bcc2c9] bg-[#ebf2fa] px-3 font-semibold ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md">
                            <img src={tiklogo} alt="" className="h-6"/>
                        </div>
                        <input required id="carte_field" type="text" onInput={e => setTiktok(e.target.value)} placeholder="Lien vers votre compte TikTok" class="ltr:rounded-l-none focus:outline-none focus:border-none w-full rtl:rounded-r-none"/>
                    </div>

                    <div class="flex my-2">
                        <div class="flex cursor-pointer items-center justify-center border border-[#bcc2c9] bg-[#ebf2fa] px-3 font-semibold ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md">
                            <img src={youlogo} alt="" className="h-6"/>
                        </div>
                        <input required id="carte_field" type="text" onInput={e => setYouTube(e.target.value)} placeholder="Lien vers votre compte YouTube" class="ltr:rounded-l-none focus:outline-none focus:border-none w-full rtl:rounded-r-none"/>
                    </div>

                    <div class="flex my-2">
                        <div class="flex cursor-pointer items-center justify-center border border-[#bcc2c9] bg-[#ebf2fa] px-3 font-semibold ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md">
                            <img src={pinlogo} alt="" className="h-6"/>
                        </div>
                        <input required id="carte_field" type="text" onInput={e => setPinterest(e.target.value)} placeholder="Lien vers votre compte Pinterest" class="ltr:rounded-l-none focus:outline-none focus:border-none w-full rtl:rounded-r-none"/>
                    </div>

                    <div class="flex my-2">
                        <div class="flex cursor-pointer items-center justify-center border border-[#bcc2c9] bg-[#ebf2fa] px-3 font-semibold ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md">
                            <img src={linklogo} alt="" className="h-6"/>
                        </div>
                        <input required id="carte_field" type="text" onInput={e => setLinkedIn(e.target.value)} placeholder="Lien vers votre compte LinkedIn" class="ltr:rounded-l-none focus:outline-none focus:border-none w-full rtl:rounded-r-none"/>
                    </div>

                    <div class="flex my-2">
                        <div class="flex cursor-pointer items-center justify-center border border-[#bcc2c9] bg-[#ebf2fa] px-3 font-semibold ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md">
                            <img src={vimlogo} alt="" className="h-6"/>
                        </div>
                        <input required id="carte_field" type="text" onInput={e => setVimeo(e.target.value)} placeholder="Lien vers votre compte Vimeo" class="ltr:rounded-l-none focus:outline-none focus:border-none w-full rtl:rounded-r-none"/>
                    </div>

                    <div class="flex justify-center mb-3">
                        <button class="btn w-full text-white bg-[#030e16] p-2"> Valider </button>
                    </div>

                </form>

            </section>
            
        </React.Fragment>
    )

}