import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import usersService from "../services/users.service";
import AdminFrame from "./AdminFrame";
import shapeService from "../services/shape.service";
import GlobalLoader from "./Spinners/GlobalLoader";

export default function LayoutAdmin()
{

    const [connected,setConnected] = useState(null)

    const [loading,setLoading] = useState(false)

    const [redirectCustomer,setRedirectCustomer] = useState(false)

    useEffect(() => {

        usersService.is_connected()
        .then(response => setConnected(response))

        usersService.is_admin()
        .then(res => setRedirectCustomer(res == false ? true : false))



    },[])

    return (

        <React.Fragment>

            {
                redirectCustomer == true && <Navigate to='/client'/>
            }

            {
                connected == false ? <Navigate to="/"/> : <AdminFrame content={<Outlet/>}></AdminFrame>
            }

        </React.Fragment>

    )

}