import React from "react";

export default function ProfileNotFound()
{

    return (

        <section class="bg-danger h-screen w-screen flex justify-center items-center px-4">
            
            <div class="bg-white overflow-hidden sm:rounded-md sm:w-6/12 w-11/12 rounded-none">
                <div class="px-6 py-8">
                    {/* <a href="index.html" class="flex justify-center mb-8">
                        <img class="h-6" src={logo} alt=""/>
                    </a> */}

                    {/* <div class="w-1/2 mx-auto block my-8">
                        <img src={logo404} title="maintenance.svg"/>
                    </div> */}

                    <h3 class="text-dark mb-4 mt-6 text-center text-3xl">Profile introuvable</h3>

                    <p class="text-dark/75 mb-8 mx-auto text-base text-center">Le profile que vous essayez d'atteindre n'existe pas. Assurez vous que le lien entré est correct et réessayez.</p>

                </div>
            </div>

        </section>

    )

}