import { InformationCircleIcon, LinkIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import usersService from "../../../services/users.service";
import OnpageLoader from "../../../components/Spinners/OnpageLoader";
import env from "../../../env";

export default function Contacts()
{

    const [loading,setLoading] = useState(true)

    const [contacts,setContacts] = useState([])

    const getContacts = async () => {

        let response = await usersService.getContacts()

        if(response == undefined)
        {
            console.log('Error getting contacts')
        }
        else{
            // alert(JSON.stringify(response))
            setContacts(response)
        }

        setLoading(false)

    }

    useEffect(() => {

        getContacts()

    },[])

    return (

        <>
            
            <section className="min-h-[70vh]">

                <header className="font-bold text-lg">

                    Mes contacts

                </header>

                {
                    loading ? <OnpageLoader/> : 

                    <article className="">

                        {
                            contacts.length > 0 ? 
                            <div className="overflow-x-auto">
                                <div className="min-w-full inline-block align-middle whitespace-nowrap">
                                    <div className="overflow-hidden">
                                        <table className="min-w-full">
                                            <thead className="bg-light/40 border-b border-gray-200">
                                                <tr>
                                                    <th className="px-6 py-3 text-start">#</th>
                                                    <th className="px-6 py-3 text-start">Nom</th>
                                                    <th className="px-6 py-3 text-start">Email</th>
                                                    <th className="px-6 py-3 text-start">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    contacts.length > 0 ? 

                                                    contacts.map((contact,i) => (

                                                        <tr className="border-b border-gray-200">
                                                            <td className="px-6 py-4">{(i + 1)}</td>
                                                            <td className="px-6 py-4">{contact.contact.name}</td>
                                                            <td className="px-6 py-4">{contact.contact.email}</td>
                                                            <td className="px-6 py-4">
                                                                <a target="_blank" href={env.APP_URL + '/carte/' + contact.contact.code} className="px-2 py-1 text-blue-700 underline text-xs rounded">
                                                                    <LinkIcon className="h-4 inline-block"/>
                                                                    <span className="">Profile</span>
                                                                </a>
                                                            </td>
                                                        </tr>

                                                    ))

                                                    : 

                                                    <tr className="border-b border-gray-200">
                                                        <td className="px-6 py-3">Aucune transaction pour le moment</td>
                                                    </tr>

                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>:
                            <div className="italic py-10 ">
                                <InformationCircleIcon className="h-5 inline-block"/>
                                <span className="">Aucun contact pour le moment</span>
                            </div>
                        }

                    </article>

                }

            </section>

        </>

    )

}