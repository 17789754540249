import React from "react";
import logo from "../../../images/logo.png"
import { Link } from "react-router-dom";

export default function PaymentFails()
{

    return (

        <section class="bg-danger h-screen w-screen flex justify-center items-center">
            <div class="2xl:w-1/4 lg:w-1/3 md:w-1/2 w-full">
                <div class="card overflow-hidden sm:rounded-md rounded-none">
                    <div class="px-6 py-8">
                        {/* <a href="index.html" class="flex justify-center mb-8">
                            <img class="h-6" src={logo} alt=""/>
                        </a> */}

                        {/* <div class="w-1/2 mx-auto block my-8">
                            <img src={logo404} title="maintenance.svg"/>
                        </div> */}

                        <h3 class="text-dark mb-4 mt-6 text-center text-3xl">Paiement annulé</h3>

                        <p class="text-dark/75 mb-8 mx-auto text-base text-center">Nous avons rencontré un problème avec votre paiement. Veuillez réessayer avec différentes informations de facturation</p>

                        <div class="flex justify-center">
                            <Link to="/client" class="btn text-white bg-danger"> Je réessaye </Link>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )

}