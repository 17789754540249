import React, { useEffect, useState } from "react";
import usersService from "../../../services/users.service";
import FillForFirst from "./Plans/FillForFirst";
import FillForSecond from "./Plans/FillForSecond";
import FillForThird from "./Plans/FillForThird";
import titleService from "../../../services/title.service";
import GlobalLoader from "../../../components/Spinners/GlobalLoader";

export default function FillPlanInfo()
{

    const [plan,setPlan] = useState(null)

    const [view,setView] = useState(null)

    const [loading,setLoading] = useState(false)

    const findVue = () => {

        if(plan != null)
        {

            if(plan.id == 1)
            {
                setView(<FillForFirst/>)
            }
            if(plan.id == 2)
            {
                setView(<FillForSecond/>)
            }
            if(plan.id == 3)
            {
                setView(<FillForThird/>)
            }

        }

    }

    const get_user_plan = async () => {

        let response = await usersService.getUserPlan()

        if(response == undefined)
        {
            console.log('Getting user plan cause service fails')
        }
        else{
            setPlan(response)
        }

    }

    useEffect(() => {

        titleService.publicInfo()

        get_user_plan()

    },[])

    useEffect(() => {

        findVue()

    },[plan])



    return (
        <React.Fragment>

            {loading ? <GlobalLoader/> : view}

            {/* {view} */}

        </React.Fragment>
    )

}