import React, { useEffect, useState } from "react";
import adminService from "../../../services/admin.service";
import OnpageLoader from "../../../components/Spinners/OnpageLoader";
import { TrashIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import usersService from "../../../services/users.service";
import { toast } from "react-toastify";
import GlobalLoader from "../../../components/Spinners/GlobalLoader";

export default function Users()
{

    const [loading,setLoading] = useState(false)

    const [users,setUsers] = useState([])
    
    const [filteredUsers,setFilteredUsers] = useState([])

    const [onLoad,setOnLoad] = useState(true)

    async function getUsers()
    {

        setOnLoad(true)

        let response = await adminService.getUsers()

        if(response == undefined)
        {
            setOnLoad(false)
            console.log('Something went wrong')
        }
        else{
            setOnLoad(false)
            setUsers(response)
        }

    }

    const deleteUser = async e => 
    {

        e.preventDefault()

        // alert(e.target['user_id'].value)

        if(window.confirm('Cette action est irréversible. Voulez vous poursuivre ?'))
        {

            setLoading(true)

            let data = {
                user_id : e.target['user_id'].value
            }
    
            let response = await usersService.deleteAccount(data)
    
            if(response)
            {
    
                await getUsers()
    
            }
            else{
                console.log('Something went wrong')
            }

            setLoading(false)

        }

    }

    const filterUsers = e => {
        
        if(e.target.value == "")
        {
            setFilteredUsers([])
        }
        else{
            const filteredData = users.filter(user => {
            
                return user.name.toLowerCase().includes(e.target.value.toLowerCase());
            });
            setFilteredUsers(filteredData)
        }

    }

    useEffect(() => {

        getUsers()

    },[])

    return (

        <React.Fragment>

            <div className="grid xl:grid-cols-1 gap-6 min-h-[500px]">

                {loading && <GlobalLoader/>}

                <div className="card overflow-hidden">

                    <div className="flex items-center mb-6 p-5 text-slate-400 shadow-current shadow-lg">
                        <UserCircleIcon className="h-6 text-slate-900"/>
                        <h4 className="text-slate-900 text-lg font-bold ml-2">Utilisateus</h4>
                    </div>
                    
                    <div className="my-5">

                        <span className="font-bold">Rechercher : </span>
                        <input required type="text" className="bg-gray-50 p-1 w-3/12 text-black" id="username" onInput={filterUsers} placeholder="Votre nom complet"/>

                    </div>

                    {onLoad ? <OnpageLoader/> : 
                        
                        <div className="overflow-x-auto">
                            <div className="min-w-full inline-block align-middle whitespace-nowrap">
                                <div className="overflow-hidden">
                                    
                                    {
                                        filteredUsers.length == 0 &&
                                        <table className="min-w-full">
                                            <thead className="bg-light/40 border-b border-gray-200">
                                                <tr>
                                                    <th className="px-6 py-3 text-start">ID</th>
                                                    <th className="px-6 py-3 text-start">Nom complet</th>
                                                    <th className="px-6 py-3 text-start">Email</th>
                                                    <th className="px-6 py-3 text-start">Téléphone</th>
                                                    <th className="px-6 py-3 text-start">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    users.length > 0 ? 

                                                    users.map((user,index) => (

                                                        <tr className="border-b border-gray-200">
                                                            <td className="px-6 py-6">{(index+1)}</td>
                                                            <td className="px-6 py-6">{user.name}</td>
                                                            <td className="px-6 py-6">{user.email}</td>
                                                            <td className="px-6 py-6">{user.phone}</td>
                                                            <td className="px-6 py-6">
                                                                <form onSubmit={deleteUser} className="inline-flex bg-white items-center justify-center">

                                                                    <input type="hidden" name="user_id" value={user.id}/>

                                                                    <button className="bg-red-500 text-white flex items-center justify-center p-1 rounded ml-2">
                                                                        <TrashIcon className="h-4"/>
                                                                        <span>Supprimer</span>
                                                                    </button>

                                                                </form>
                                                            </td>
                                                        </tr>

                                                    ))

                                                    : 

                                                    <tr className="border-b border-gray-200">
                                                        <td className="px-6 py-3">Aucun utilisateur pour le moment</td>
                                                    </tr>

                                                }
                                            </tbody>
                                        </table>
                                    }

                                    {
                                        filteredUsers.length > 0 && 

                                        <table className="min-w-full">
                                            <thead className="bg-light/40 border-b border-gray-200">
                                                <tr>
                                                    <th className="px-6 py-3 text-start">ID</th>
                                                    <th className="px-6 py-3 text-start">Nom complet</th>
                                                    <th className="px-6 py-3 text-start">Email</th>
                                                    <th className="px-6 py-3 text-start">Téléphone</th>
                                                    <th className="px-6 py-3 text-start">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    filteredUsers.length > 0 ? 

                                                    filteredUsers.map((fuser,index) => (

                                                        <tr className="border-b border-gray-200">
                                                            <td className="px-6 py-6">{(index+1)}</td>
                                                            <td className="px-6 py-6">{fuser.name}</td>
                                                            <td className="px-6 py-6">{fuser.email}</td>
                                                            <td className="px-6 py-6">{fuser.phone}</td>
                                                            <td className="px-6 py-6">
                                                                <form onSubmit={deleteUser} className="inline-flex bg-white items-center justify-center">

                                                                    <input type="hidden" name="user_id" value={fuser.id}/>

                                                                    <button className="bg-red-500 text-white flex items-center justify-center p-1 rounded ml-2">
                                                                        <TrashIcon className="h-4"/>
                                                                        <span>Supprimer</span>
                                                                    </button>

                                                                </form>
                                                            </td>
                                                        </tr>

                                                    ))

                                                    : 

                                                    <tr className="border-b border-gray-200">
                                                        <td className="px-6 py-3">Aucun utilisateur pour le moment</td>
                                                    </tr>

                                                }
                                            </tbody>
                                        </table>

                                    }

                                </div>
                            </div>
                        </div>
                    
                    }
                    
                </div>

            </div>

        </React.Fragment>

    )

}