import React, { useEffect, useState } from "react";
import adminService from "../../../services/admin.service";
import GlobalLoader from "../../../components/Spinners/GlobalLoader";

import { TrashIcon, QueueListIcon } from '@heroicons/react/24/outline'
import OnpageLoader from "../../../components/Spinners/OnpageLoader";
import usersService from "../../../services/users.service";


export default function Plans()
{

    const [plans,setPlans] = useState([])

    const [name,setName] = useState(null)

    const [description,setDescription] = useState(null)

    const [price,setPrice] = useState(null)

    const [loading,setLoading] = useState(false)

    const [onLoad,setOnLoad] = useState(false)

    const [rights,setRights] = useState([])

    const [permissions,setPermissions] = useState([])

    const [plan,setPlan] = useState(null)

    const get_plans = async () => {

        setOnLoad(true)

        let response = await adminService.getPlans()

        if(response == undefined)
        {
            setOnLoad(false)
            alert('Admin service error')
        }
        else{
            setOnLoad(false)
            setPlans(response)
        }

    }

    const handlePlanCreation = async e => {

        e.preventDefault()

        setLoading(true)

        let data = {
            name : name,
            description : description,
            price : price
        }

        let response = await adminService.createPlan(data)

        if(response == undefined)
        {
            alert('Admin service error while creating a plan')
        }
        else{
            await get_plans()
        }

        setLoading(false)

    }

    const get_rights = async () => {

        let response = await adminService.get_rights()

        if(response == undefined)
        {
            alert('Admin service error while getting rights')
        }
        else{
            setRights(response)
        }

    }
    

    const handlePermissionUpdate = async e => {

        e.preventDefault()

        setLoading(true)

        // Looping through permissions

        let permissions = e.target.permissions

        let checkedPerms = []

        for (let i = 0; i < permissions.length; i++) {
            if (permissions[i].checked) {
                checkedPerms.push(permissions[i].value);
            }
        }

        let data = {permissions : checkedPerms,plan : e.target['plan'].value}

        let response = await adminService.savePlanPermissions(data)

        if(response == undefined)
        {
            alert('Error creating right for this plan')
        }
        else{

            // setPlans(prevs => prevs.map(plan => plan.id == response.plan.id ? response.plan : plan))
            await get_plans()

        }

        setLoading(false)

    }

    const handleRightRemoval = async e => {

        e.preventDefault()

        setLoading(true)

        let data = {
            right : e.target.closest('form')['right'].value,
            plan : e.target.closest('form')['plan'].value
        }

        // Here send request

        let response = await adminService.removeRight(data)

        if(response == undefined)
        {
            alert('Error creating right for this plan')
        }
        else{

            // setPlans(prevs => prevs.map(plan => plan.id == response.plan.id ? response.plan : plan))
            await get_plans()

        }

        setLoading(false)

    }

    const handlePlanDeletion = async e => {

        e.preventDefault()

        if(window.confirm('Cette opération est irréversible. Voulez vous poursuivre ?'))
        {

            setLoading(true)

            let data = {plan_id : e.target['plan_id'].value}
    
            let response = await usersService.deletePlan(data)
    
            if(response)
            {
                // Reefetch all plans
                await get_plans()
            }
            else{
                console.log('Error while deleting the plan')
            }
    
            setLoading(false)

        }

    }

    useEffect(() => {

        get_plans()

        get_rights()

        // alert(JSON.stringify(plans))

    },[])

    return (
        <>

                    <div class="card min-h-[70vh]">
                        {loading && <GlobalLoader/>}
                        {onLoad ? <OnpageLoader/> :
                        
                            <div class="p-6">

                                <div className="flex items-center mb-6 p-5 text-slate-400 shadow-current shadow-lg">
                                    <QueueListIcon className="h-6 text-slate-900"/>
                                    <h4 className="text-slate-900 text-lg font-bold ml-2">Les plans disponibles</h4>
                                </div>

                                <div data-fc-type="accordion" class="rounded border divide-y border-gray-200 divide-slate-900/10">

                                    {
                                        plans.length > 0 ? 
                                        plans.map(plan => (

                                            <>
                                                <button data-fc-type="collapse" class="fc-collapse-open:text-primary p-3 inline-flex items-center justify-between gap-x-3 w-full font-semibold text-left text-gray-800 transition hover:text-gray-500">
                                                    {plan.name} (€{plan.price})
                                                    
                                                    <form onSubmit={handlePlanDeletion} className="inline-flex bg-white items-center justify-center">

                                                        <input type="hidden" name="plan_id" value={plan.id}/>

                                                        <button className="bg-red-500 text-white flex items-center justify-center p-1 rounded ml-2">
                                                            <TrashIcon className="h-4"/>
                                                            <span>Supprimer</span>
                                                        </button>

                                                    </form>
                                                </button>
                                                <div class="w-full overflow-hidden transition-[height] duration-300">
                                                    <p class="p-3 text-gray-800">

                                                        <header className="font-extrabold text-lg mb-3">Droits du plan</header>
                                                        <div className="mb-5">
                                                            {
                                                                plan.rights.length  > 0 ? 
                                                                <div className="">
                                                                {
                                                                    plan.rights.map(right => (
                                                                        <form onSubmit={handleRightRemoval} className="inline-flex mr-4 py-3 bg-white shadow-lg px-4 items-center justify-center mb-4">

                                                                            <b className="">{right.permission.name}</b>

                                                                            <input type="hidden" name="right" value={right.id}/>

                                                                            <input type="hidden" name="plan" value={plan.id}/>

                                                                            <button className="bg-red-500 text-white flex items-center justify-center p-1 rounded ml-2">
                                                                                <TrashIcon className="h-4"/>
                                                                            </button>

                                                                        </form>
                                                                    ))
                                                                }
                                                                </div> :
                                                                <div className="">Aucun droit pour le moment</div>
                                                            }
                                                        </div>

                                                        {
                                                            rights.filter(right => !plan.rights.some(planRight => planRight.perm === right.id)).length > 0 && 

                                                            <form onSubmit={handlePermissionUpdate}>

                                                                {
                                                                    rights.filter(right => !plan.rights.some(planRight => planRight.perm === right.id)).map(right => (
                                                                        <div class="inline-flex mr-4 items-center mb-4">
                                                                                <input type="checkbox" name="permissions" value={right.id} class="form-checkbox rounded" id="checkbox-signin"/>
                                                                                <label class="ms-2" for="checkbox-signin">{right.name}</label>
                                                                            </div>
                                                                    ))
                                                                }
                                                                {/* rights.map(right => (

                                                                        <>

                                                                            <div class="inline-flex mr-4 items-center mb-4">
                                                                                <input type="checkbox" name="permissions" value={right.id} class="form-checkbox rounded" id="checkbox-signin"/>
                                                                                <label class="ms-2" for="checkbox-signin">{right.name}</label>
                                                                            </div>

                                                                        </>

                                                                    )) */}

                                                                <input type="hidden" name="plan" id="vc_plan_id" value={plan.id}/>

                                                                <div class="flex mb-3">
                                                                    <button class="btn text-white bg-[#030e16] p-2"> Mettre à jour </button>
                                                                </div>

                                                            </form>

                                                        }
                                                        
                                                        

                                                    </p>
                                                </div>
                                            </>

                                        )) : 
                                        <div className="">Aucun plan disponible pour le moment</div>
                                    }

                                </div>
                            </div>
                        
                        }
                        
                    </div>

                    <div class="card mt-10">
                        <div class="p-6">
                            <h4 class="card-title mb-4">Ajouter un plan</h4>

                            {/* Add plan creation form here */}

                            <form onSubmit={handlePlanCreation}>

                                <div class="mb-4">
                                    <label class="mb-2" for="LoggingEmailAddress">Dénomination du plan</label>
                                    <input required onInput={e => setName(e.target.value)} id="LoggingEmailAddress" class="w-full block my-2 placeholder:text-gray-700" type="text" placeholder="Dénomination du plan"/>
                                </div>

                                <div class="mb-4">
                                    <label class="mb-2" for="LoggingEmailAddress">Prix d'achat</label>
                                    <input required onInput={e => setPrice(e.target.value)} id="LoggingEmailAddress" class="w-full block my-2 placeholder:text-gray-700" type="number" step="0.01" placeholder="Prix d'achat"/>
                                </div>

                                <div class="flex justify-center mb-3">
                                    <button class="btn w-full text-white bg-[#030e16] p-2"> Ajouter </button>
                                </div>

                            </form>
                            
                        </div>
                    </div>

        </>
    )

}