import React, { createContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import usersService from "../../../services/users.service";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalLoader from "../../../components/Spinners/GlobalLoader";
import QRCode from "qrcode.react";
import ProfileNotFound from "./ProfileNotFound";
import { TrashIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import AddContact from "../../../components/AddContact";

const InfoCtx = createContext({
    info : null,
    setInfo : () =>{}
})

export default function CardLayout()
{

    const params = useParams()

    const ucode = params.ucode

    const [info,setInfo] = useState([])

    const [loading,setLoading] = useState(false)
    
    const [error,setError] = useState(false)

    const infoMemo = useMemo(() => ({info:info,setInfo:setInfo}))

    const getProfileInfo = async () => {

        setLoading(true)

        let data = {
            ucode : ucode
        }

        let response = await usersService.getUserCardInfo(data)

        if(response == undefined)
        {
            setError(true)
        }
        else{
            setInfo(response)
        }

        setLoading(false)

    }

    useEffect(() => {

        getProfileInfo()

    },[])

    return (
        <React.Fragment>

            <ToastContainer/>

            {loading && <GlobalLoader/>}

            {
                !loading && 

                error == true ? <ProfileNotFound/> : 
                <section className="lg:w-7/12 shadow-lg min-h-[100vh] mx-auto">

                    <header className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 p-5 bg-gradient-to-r from-blue-700 to-blue-500">
                        
                        <div className="">

                            <div className="font-extrabold flex text-lg text-white lg:justify-start md:justify-start sm:justify-start justify-center">
                                Bienvenue sur mon portail
                            </div>

                            <div className="h-14 w-14 lg:mx-0 md:mx-0 sm:mx-0 mx-auto my-5 flex rounded-full bg-gray-300 mt-6 lg:justify-start md:justify-start sm:justify-start justify-center">
                                <img src={info.pic} alt="" className="h-14"/>
                            </div>

                        </div>

                        <div className="flex items-center lg:justify-end md:justify-end sm:justify-end justify-center">
                            <QRCode value={window.location.href}/>
                        </div>

                    </header>

                    <div className="p-5 min-h-[60vh]">

                        <div className="my-5">
                            <b className="">Nom complet : </b><span>{info.cname}</span>
                        </div>
                        {
                            info?.plan?.id != 3 && 
                            <div className="">
                                <b className="">Numéro de téléphone : </b><span>{info.phone}</span>
                            </div>
                        }

                        {
                            info?.plan?.id == 2 && 
                            <div className="">

                                <div className="my-5">
                                    <b className="">Email : </b><span>{info.email}</span>
                                </div>

                                <div className="my-5">
                                    <b className="">Adresse personnelle : </b><span>{info.address}</span>
                                </div>

                                <div className="my-5">
                                    <b className="">Mon site web : </b><a href={info.website.link} target="_blank" className="text-blue-500 underline">Visitez mon site web</a>
                                </div>

                                <div className="my-5">
                                    <b className="">Mes réseaux sociaux</b>
                                </div>

                                <div className="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-4 grid-cols-2 gap-4">

                                {
                                    info?.socials?.map(social => (

                                        <div className="my-5 flex items-center gap-2">
                                            <b className="">
                                                <img src={social.realmedia.pic} alt="" className="h-6"/>
                                            </b>
                                            <a href={social.link} target="_blank" className="text-blue-600">
                                                <i className="ph-duotone ph-link"></i>
                                                <span>Visiter</span>
                                            </a>
                                        </div>
                                        
                                    ))
                                }

                                </div>

                            </div>
                        }

                        {/* Plan 3 */}

                        {
                            info?.plan?.id == 3 && 
                            <div className="">

                                {
                                    info.mailpro != null && 
                                    <div className="my-5">
                                        <b className="">Mail pro : </b><span>{info.mailpro}</span>
                                    </div>
                                }

                                <div className="my-5">
                                    <b className="">Entreprise : </b> <span className="">{info.venture}</span>
                                </div>

                                <div className="my-5">
                                    <b className="">Position : </b> <span className="">{info.position}</span>
                                </div>

                                    {
                                        info.venture_address != null && 
                                        <div className="my-5">
                                            <b className="">Adresse de {info.venture} : </b><span>{info.venture_address}</span>
                                        </div>
                                    }

                                <div className="my-5">
                                    <b className="">Numéro de téléphone : </b> <span className="">{info.phonepro1}</span>
                                </div>

                                <div className="my-5">
                                    <b className="">Mon site web : </b><a href={info.website.link} target="_blank" className="text-blue-500 underline">Visitez mon site web</a>
                                </div>

                                <div className="my-5">
                                    <b className="">Mes réseaux sociaux</b>
                                </div>

                                <div className="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-4 grid-cols-2 gap-4">

                                {
                                    info?.socials?.map(social => (

                                        <div className="my-5 flex items-center gap-2">
                                            <b className="">
                                                <img src={social.realmedia.pic} alt="" className="h-6"/>
                                            </b>
                                            <a href={social.link} target="_blank" className="text-blue-600">
                                                <i className="ph-duotone ph-link"></i>
                                                <span>Visiter</span>
                                            </a>
                                        </div>
                                        
                                    ))
                                }

                                </div>

                            </div>
                        }

                    </div>

                    <InfoCtx.Provider value={infoMemo}>
                        <AddContact infoctx={InfoCtx}/>
                    </InfoCtx.Provider>

                    <div className="bg-gray-300 p-10 text-gray-400">
                        &copy; cartevirtuelle.shop
                    </div>

                </section>

            }

        </React.Fragment>
    )
}