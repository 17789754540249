import React, { createContext, useEffect, useMemo, useRef, useState } from "react";
import titleService from "../../../services/title.service";
import QRCode from "qrcode.react";
import env from "../../../env";
import ClipboardJS from "clipboard";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usersService from "../../../services/users.service";
import Normal from "./ProfileComponents/Normal";
import NormalPlus from "./ProfileComponents/NormalPlus";
import Professionnal from "./ProfileComponents/Professionnal";
import { Bars3Icon, BellIcon, ClipboardIcon } from '@heroicons/react/24/outline'
import sendRequest from "../../../services/request.service";
import { GET_STEP } from "../../../routes/routes";
import { useLocation, useNavigate } from "react-router-dom";
import OnpageLoader from "../../../components/Spinners/OnpageLoader";
import GlobalLoader from "../../../components/Spinners/GlobalLoader";

export default function IndexCustomer()
{

    const qrRef = useRef()

    const [info,setInfo] = useState([])

    const infoMemo = useMemo(() => ({info:info,setInfo:setInfo}))

    const navigate = useNavigate()

    const location = useLocation()

    const [loading,setLoading] = useState(true)

    const [isLoading,setIsLoading] = useState(false)

    const [online,setOnline] = useState(true)

    const downloadQRCode = () => {
        const canvas = qrRef.current.querySelector('canvas');
        const image = canvas.toDataURL("image/png");
        const link = document.createElement('a');
        link.href = image;
        link.download = 'qrcode.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const isOnline = async () => {

        let response = await usersService.isOnline()

        if(response == undefined)
        {
            console.log('Error getting status')
        }
        else{
            setOnline(response)
        }

    }

    const toggleOnline = async () => {

        setIsLoading(true)

        let data = {
            status : online ? false : true
        }

        let response = await usersService.toggleOnline(data)

        if(response == undefined)
        {
            setIsLoading(false)
            console.log('Error toggling status')
        }
        else{
            setIsLoading(false)
            setOnline(response)
        }

    }

    const getProfileInfo = async () => {

        setLoading(true)

        let response = await usersService.getProfileInfo()

        if(response == undefined)
        {
            setLoading(false)
            console.log('Service error')
        }
        else{
            setLoading(false)
            // alert(JSON.stringify(response))
            setInfo(response)
        }

    }

    const getStep = async () => {

        let response = await sendRequest.getData(`${GET_STEP}/${JSON.parse(localStorage.getItem('vc_user')).id}`)
    
        // alert(JSON.stringify(response))
    
        return response.status == 200 ? response.data : undefined;
    
    }

    useEffect(() => {

        titleService.clientIndexTitle()

    },[])

    useEffect(() => {

        new ClipboardJS('#copyButton', {
            target: function(trigger) {
                return document.querySelector('#carte_field');
            }
        })

        getProfileInfo()

        isOnline()

    },[])

    return (
        <React.Fragment>

            <div className="min-h-[70vh]">

                {isLoading && <GlobalLoader/>}

                {loading ? <OnpageLoader/> : 
                    
                    <>
                        
                        <header className="font-extrabold text-lg my-10 px-4">Vos informations publiques sur votre carte</header>

                        <section className="px-4">

                            <ToastContainer/>

                            <article className="grid lg:grid-cols-2">

                                <div className="">

                                    <div className="">
                                        <img src={JSON.parse(localStorage.getItem('vc_user')).pic} alt="" className="w-14"/>
                                    </div>

                                    <div className="">

                                        {/* Here i should display plan info */}

                                        <div className="my-5">
                                            <b className="">Nom complet : </b><span>{info.cname}</span>
                                        </div>
                                        <div className="">
                                            <b className="">Numéro de téléphone : </b><span>{info.phone}</span>
                                        </div>

                                        {
                                            info?.plan?.id == 2 && 
                                            <div className="">

                                                <div className="my-5">
                                                    <b className="">Email : </b><span>{info.email}</span>
                                                </div>

                                                <div className="my-5">
                                                    <b className="">Adresse personnelle : </b><span>{info.address}</span>
                                                </div>

                                                <div className="grid sm:grid-cols-4 grid-cols-2 gap-4">

                                                {
                                                    info?.socials?.map(social => (

                                                        <div className="my-5 flex items-center gap-2">
                                                            <b className="">
                                                                <img src={social.realmedia.pic} alt="" className="h-6"/>
                                                            </b>
                                                            <a href={social.link} target="_blank" className="text-blue-600">
                                                                <i className="ph-duotone ph-link"></i>
                                                                <span>Visiter</span>
                                                            </a>
                                                        </div>
                                                        
                                                    ))
                                                }

                                                </div>

                                            </div>
                                        }

                                        {/* When plan 3 */}

                                        {
                                            info?.plan?.id == 3 && 
                                            <div className="">

                                                {
                                                    info.mailpro != null && 
                                                    <div className="my-5">
                                                        <b className="">Mail pro : </b><span>{info.mailpro}</span>
                                                    </div>
                                                }

                                                {
                                                    info.venture != null && 
                                                    <div className="my-5">
                                                        <b className="">Entreprise : </b><span>{info.venture}</span>
                                                    </div>
                                                }

                                                {
                                                    info.position != null && 
                                                    <div className="my-5">
                                                        <b className="">Poste : </b><span>{info.position}</span>
                                                    </div>
                                                }

                                                {
                                                    info.venture_address != null && 
                                                    <div className="my-5">
                                                        <b className="">Adresse de {info.venture} : </b><span>{info.venture_address}</span>
                                                    </div>
                                                }

                                                <div className="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-4 grid-cols-2 gap-4">

                                                    {
                                                        info?.socials?.map(social => (

                                                            <div className="my-5 flex items-center gap-2">
                                                                <b className="">
                                                                    <img src={social.realmedia.pic} alt="" className="h-6"/>
                                                                </b>
                                                                <a href={social.link} target="_blank" className="text-blue-600">
                                                                    <i className="ph-duotone ph-link"></i>
                                                                    <span>Visiter</span>
                                                                </a>
                                                            </div>
                                                            
                                                        ))
                                                    }

                                                </div>

                                            </div>
                                        }

                                    </div>

                                    <div className="">
                                    
                                        {
                                            online ? <button onClick={toggleOnline} className="bg-red-700 block my-4 text-white font-bold text-sm p-2">Mettre hors ligne</button> :
                                            <button onClick={toggleOnline} className="bg-green-700 block my-4 text-white font-bold text-sm p-2">Mettre en ligne</button>
                                        }
                                        
                                    </div>


                                </div>

                                <div className="relative">

                                    {
                                        !online && <div className="absolute inset-0 backdrop-blur-sm"></div>
                                    }

                                    <div className="my-5">

                                        <header className="mb-4">Copier et utilisez le lien ci dessus pour visiter votre carte virtuelle</header>

                                        <div class="flex">
                                            <div onClick={() => toast.success('Lien copié avec succès')} id="copyButton" class="flex cursor-pointer items-center justify-center border border-[#bcc2c9] bg-[#bcc2c9] px-3 font-semibold ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md">
                                                <ClipboardIcon className="h-4"/>
                                            </div>
                                            <input id="carte_field" type="text" placeholder="Username" value={`${env.APP_URL}/carte/${JSON.parse(localStorage.getItem('vc_user')).code}`} class="ltr:rounded-l-none w-full rtl:rounded-r-none" readOnly/>
                                        </div>

                                        </div>

                                        <div className="">
                                        <header className="mb-4">Partagez ce QRCode avec vos contacts</header>
                                        <div ref={qrRef}>
                                            <QRCode value={`${env.APP_URL}/carte/${JSON.parse(localStorage.getItem('vc_user')).code}`}/>
                                        </div>
                                        <div className="my-5">
                                            <button className="btn inline-block text-white bg-[#030e16] p-2" onClick={downloadQRCode}>Télécharger le QRCode</button>
                                        </div>

                                    </div>

                                </div>

                            </article>

                        </section>

                    </>
                
                }

            </div>

        </React.Fragment>
    )
}