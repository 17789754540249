const IS_CONNECTED = 'is-connected'
const LOGIN = 'login'
const REGISTER = 'register'
const CONFIRM_USER_EMAIL = 'confirm-email-address'

// Admin routes

const USER_COUNT = 'get-user-count'
const ONLINE_PROFILES_COUNT = 'get-online-profiles'
const PAYMENT_SUMMARY = 'get-payments-summary'
const GET_PLANS = 'get-plans'
const GET_RIGHTS = 'get-rights'
const CREATE_PLAN = 'create-plan'
const SAVE_PLAN_PERMISSIONS = 'save-plan-permission'
const REMOVE_RIGHT = 'remove-right'
const GET_MEDIA = 'get-media'
const CREATE_MEDIA = 'create-media'
const DELETE_MEDIA = 'delete-media'
const UPDATE_PROFILE = 'update-profile'
const GET_STEP = 'get-step'
const CREATE_STEP = 'create-step'
const SETTLE_STEP = 'settle-step'
const GET_USER_PLAN = 'get-user-plan'

const GET_PROFILE_INFO = 'get-profile-info'

const CREATE_STRIPE_PAYMENT = 'create-stripe-payment'

const ACTIVATE_ONLINE_CARD = 'activate-online-card'

const GET_USER_CARD_INFO = 'get-user-card-info'

const LOGOUT = 'logout'

// Password recuperation

const SEND_VERIFICATION_EMAIL = 'send-confirmation-code'
const VALIDATE_CONFIRMATION_EMAIL = 'validate-confirmation-code'
const RESET_PASSWORD = 'reset-password'

const RESEND_VALIDATION_CODE = 'resend-validation-code'

const UPDATE_PROFILE_INFO = 'update-profile-info'

const DELETE_ACCOUNT = 'delete-account'

const GET_USERS = 'get-users'

const IS_ONLINE = 'is-online'

const TOGGLE_ONLINE = 'set-online'

const GET_CONTACTS = 'get-contacts'

const CREATE_CONTACT = 'create-contact'

const HAS_CONTACT = 'has-contact'

const DELETE_PLAN = 'delete-plan'

export {IS_CONNECTED,LOGIN,REGISTER,CONFIRM_USER_EMAIL,LOGOUT,
    USER_COUNT,ONLINE_PROFILES_COUNT,PAYMENT_SUMMARY,
    GET_PLANS,GET_RIGHTS,CREATE_PLAN,SAVE_PLAN_PERMISSIONS,
    REMOVE_RIGHT,GET_MEDIA,CREATE_MEDIA,DELETE_MEDIA,
    UPDATE_PROFILE,GET_STEP,CREATE_STRIPE_PAYMENT,
    CREATE_STEP,SETTLE_STEP,GET_USER_PLAN,
    GET_PROFILE_INFO,ACTIVATE_ONLINE_CARD,GET_USER_CARD_INFO,
    SEND_VERIFICATION_EMAIL,VALIDATE_CONFIRMATION_EMAIL,
    RESET_PASSWORD,UPDATE_PROFILE_INFO,RESEND_VALIDATION_CODE,
    DELETE_ACCOUNT,GET_USERS,IS_ONLINE,TOGGLE_ONLINE,
    GET_CONTACTS,CREATE_CONTACT,HAS_CONTACT,DELETE_PLAN}