import React, { createContext, useEffect, useMemo, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import usersService from "../services/users.service";
import CustomerFrame from "./CustomerFrame";
import shapeService from "../services/shape.service";

export const StepContext = createContext({
    step : null,
    setStep : () => {}
})

export const ShouldRFLContext = createContext({
    shouldRedirectFirstLogin : false,
    setShouldRedirectFirstLogin : () => {}
})

export default function LayoutCustomer()
{

    const [connected,setConnected] = useState(null)

    const [redirectAdmin,setRedirectAdmin] = useState(false)

    const [loading,setLoading] = useState(false)

    const [shouldRedirectFirstLogin,setShouldRedirectFirstLogin] = useState(null)

    const shouldRFLMemo = useMemo(() => ({shouldRedirectFirstLogin:shouldRedirectFirstLogin,setShouldRedirectFirstLogin:setShouldRedirectFirstLogin}))

    const [step,setStep] = useState({})

    const stepMemo = useMemo(() => ({step:step,setStep:setStep}))

    const location = useLocation()

    const navigate = useNavigate()

    const [redirectPath,setRedirectPath] = useState(null)

    const determineRedirectPath = async () => {

        let redirectPath = null

        if (redirectAdmin) {
            redirectPath = '/admin'
        }
        if (connected == false) {
            redirectPath = '/'
        }
        if (shouldRedirectFirstLogin == true) {
            redirectPath = '/client/remplir-profile'
        }
        else if(step != {}){

            // alert(JSON.stringify(step))

            if (step.step === 0 && step.status === 0) {
                redirectPath = '/client/choisir-plan'
            }
            else if(step.step === 1 && step.status === 1)
            {
                redirectPath = '/client/info-a-afficher'
            }
            else if(step.step === 2 && step.status === 1)
            {

                // alert(location.pathname)

                redirectPath = null
                
            }
            // else{
            //     setRedirectPath(null)
            // }

        }
        // Return null if no redirect is needed
        return redirectPath
        
    };

    useEffect(() => {

        usersService.is_connected()
        .then(response => setConnected(response))

        usersService.is_admin()
        .then(res => setRedirectAdmin(res == true ? true : false))

        const firstLogin = () => {

            let res = usersService.is_first_login()

            return res

        }

        if(firstLogin())
        {
            // Then instruct to redirect to profile update route
            setShouldRedirectFirstLogin(true)
        }

        // Check the account's step and redirect the use accordingly

        usersService.getStep()
        .then(response => {

            // alert(JSON.stringify(response))

            if(response == undefined)
            {
                console.log('Something went wrong fetching steps')
            }
            else{
                setStep(response.step != null ? response.step : {})
                // alert(JSON.stringify(step))
            }

        })

        

    },[])

    // useState(() => {

        

    // },[])

    useEffect(() => {

        determineRedirectPath()
        .then(res => setRedirectPath(res))

    },[shouldRedirectFirstLogin,step])

    return (

        <React.Fragment>

            {
                redirectPath != null && <Navigate to={redirectPath}/>
            }

            {
                connected == false ? <Navigate to="/"/> : 

                !loading && 
                <StepContext.Provider value={stepMemo}>
                    <ShouldRFLContext.Provider value={shouldRFLMemo}>
                        <CustomerFrame content={<Outlet/>}></CustomerFrame>
                    </ShouldRFLContext.Provider>
                </StepContext.Provider>
            }

            {/* {
                shouldRedirectFirstLogin == true && <Navigate to="/client/remplir-profile"/>
            }

            {
                (step.step == 0 && step.status == 0 ) && <Navigate to="/client/choisir-plan"/>
            }

            {
                (step.step == 0 && step.status == 1 ) && <Navigate to="/client/methode-de-paiement"/>
            } */}

        </React.Fragment>

    )

}