import React, { createContext, useEffect, useMemo, useState } from "react";
import usersService from "../../../services/users.service";
import Normal from "./ProfileComponents/Normal";
import NormalPlus from "./ProfileComponents/NormalPlus";
import Professionnal from "./ProfileComponents/Professionnal";
import { useNavigate } from "react-router-dom";
import { TrashIcon } from "@heroicons/react/24/outline";
import OnpageLoader from "../../../components/Spinners/OnpageLoader";

const InfoCtx = createContext({
    info : null,
    setInfo : () => {}
})

export default function Profile()
{

    const [info,setInfo] = useState(null)

    const infoMemo = useMemo(() => ({info:info,setInfo:setInfo}))

    const [loading,setLoading] = useState(true)

    const navigate = useNavigate()

    const getProfileInfo = async () => {

        setLoading(true)

        let response = await usersService.getProfileInfo()

        if(response == undefined)
        {
            setLoading(false)
            console.log('Service error')
        }
        else{
            // alert(JSON.stringify(response))
            setInfo(response)
            setLoading(false)
        }

    }

    const deleteAccount = async e => {

        if(window.confirm('Cette action est irréversible, voulez vous poursuivre ?'))
        {
  
            // Handle account deletion action here
  
            setLoading(true)
  
            let data = {
                user_id : JSON.parse(localStorage.getItem('vc_user')).id
            }
  
            let response = await usersService.deleteAccount(data)
  
            if(response)
            {
                // Redirect the user to the login page
                navigate('/')
            }
            else{
                alert('Erreur lors de la suppression')
            }
            
            setLoading(false)
  
        }
  
    }

    useEffect(() => {

        getProfileInfo()

    },[])


    return (

        <React.Fragment>

            <div className="min-h-[70vh]">

                {loading ? <OnpageLoader/> :
                    
                    <>
                        <header className="font-extrabold text-lg my-6 px-4">Modifiez vos informations</header>

                        <section id="update-infos" className="">

                            {
                                info?.plan?.id == 1 &&
                                <InfoCtx.Provider value={infoMemo}>
                                    <Normal ctx={InfoCtx}/>
                                </InfoCtx.Provider>
                            }

                            {
                                info?.plan?.id == 2 &&
                                <InfoCtx.Provider value={infoMemo}>
                                    <NormalPlus ctx={InfoCtx}/>
                                </InfoCtx.Provider>
                            }

                            {
                                info?.plan?.id == 3 && 

                                <InfoCtx.Provider value={infoMemo}>
                                    <Professionnal ctx={InfoCtx}/>
                                </InfoCtx.Provider>

                            }

                        </section>

                        <section className="px-4">

                            <header className="font-extrabold text-lg py-5">
                                Actions sur mon compte
                            </header>

                            <div className="">
                                <button onClick={deleteAccount} className="flex items-center bg-red-700 rounded-md text-white p-2">
                                    <TrashIcon className="w-4"/>
                                    <span className="">Supprimer le compte</span>
                                </button>
                            </div>

                        </section>
                    </>
                
                }

            </div>

        </React.Fragment>

    )

}