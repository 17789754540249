import React from "react";
import logo404 from "../images/404-error.svg"
import logo from "../images/logo.png"
import { Link } from "react-router-dom";

export default function NotFound404()
{

    return (
        
        <section class="bg-primary h-screen w-screen flex justify-center items-center">
            <div class="2xl:w-1/4 lg:w-1/3 md:w-1/2 w-full">
                <div class="card overflow-hidden sm:rounded-md rounded-none">
                    <div class="px-6 py-8">
                        <a href="index.html" class="flex justify-center mb-8">
                            <img class="h-6" src={logo} alt=""/>
                        </a>

                        <div class="w-1/2 mx-auto block my-8">
                            <img src={logo404} title="maintenance.svg"/>
                        </div>

                        <h3 class="text-dark mb-4 mt-6 text-center text-3xl">Page introuvable</h3>

                        <p class="text-dark/75 mb-8 mx-auto text-base text-center">L'adresse que vous avez fourni n'est liée à aucune page. Veuillez vérifier cette adresse.</p>

                        <div class="flex justify-center">
                            <Link to="/" class="btn text-white bg-primary"> Retourner à l'accueil </Link>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    )

}