import React from "react"

const signTitle = () => {

    document.title = 'S\'inscrire'

}

const loginTitle = () => {

    document.title = 'Se connecter'

}

const passwordRecoveryTitle = () => {

    document.title = 'Récupération de mot de passe'

}

// Admin titles

const adminIndexTitle = () => {
    document.title = 'Page d\'administration'
}

const plansTitle = () => {
    document.title = 'Vos plans'
}

const paymentTitle = () => {
    document.title = 'Historique des transactions'
}

// Customers titles

const clientIndexTitle = () => {
    document.title = 'Mon compte'
}

const publicInfo = () => {
    document.title = 'Remplissez les informations à afficher'
}

export default {signTitle,loginTitle,passwordRecoveryTitle,
    adminIndexTitle,clientIndexTitle,paymentTitle,publicInfo}