import React, { useEffect, useState } from "react";
import logo from "../../images/logo.png"
import { Link, Navigate, useNavigate } from "react-router-dom";
import titleService from "../../services/title.service";
import GlobalLoader from "../../components/Spinners/GlobalLoader";
import usersService from "../../services/users.service";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function SignUp()
{

    const [name,setName] = useState(null)

    const [email,setEmail] = useState(null)

    const [password,setPassword] = useState(null)

    const [loading,setLoading] = useState(false)

    const [redirect,setRedirect] = useState(false)

    const navigate = useNavigate()

    const handleSubmit = async e => {

        setLoading(true)

        e.preventDefault()

        if(name == null || email == null || password == null)
        {
            toast.error('Veuillez remplir tous les champs')
        }
        else{

            let data = {

                name : name,
                email : email,
                password : password
    
            }
    
            let response = await usersService.create_user(data)

            if(response)
            {
                navigate('/mail-confirmation')
            }
            else{
                toast.error('Erreur')
            }
    
            setLoading(false)

        }

    }

    useEffect(() => {

        titleService.signTitle()

    },[])

    return (
        <>

            {/* {redirect && <Navigate to='/mail-confirmation'/>} */}

            {loading && <GlobalLoader/>}

            <section className="bg-[#3063a3] h-screen w-screen flex justify-center items-center">
                
                <form onSubmit={handleSubmit} className="bg-white text-white lg:w-4/12 md:w-6/12 lg:p-6 md:p-6 sm:w-7/12 sm:p-4 w-11/12 p-5">

                    {loading && <GlobalLoader/>}

                    <div class="flex justify-center mb-8">
                        <img class="h-10" src={logo} alt=""/>
                    </div>

                    <div className="pb-4">
                        <label htmlFor="username" className="block py-4 text-black">Votre nom complet</label>
                        <input required type="text" className="bg-gray-100 p-2 w-full text-black" id="username" onInput={(e) => setName(e.target.value)} placeholder="Votre nom complet"/>
                    </div>

                    <div className="pb-4">
                        <label htmlFor="username" className="block py-2 text-black">Votre email</label>
                        <input required type="email" className="bg-gray-100 p-2 w-full text-black" id="username" onInput={(e) => setEmail(e.target.value)} placeholder="Votre email"/>
                    </div>

                    <div className="pb-4">
                        <div class="flex items-center justify-between mb-2 text-black">
                            <label for="password">Mot de passe</label>
                        </div>
                        {/* <label htmlFor="password" className="block py-4">Mot de passe</label> */}
                        <input required type="password" id="password" className="bg-gray-100 p-2 w-full text-black" onInput={(e) => setPassword(e.target.value)}  placeholder="Votre mot de passe"/>
                    </div>

                    <div class="flex items-center mb-4 text-black">
                        <input required type="checkbox" class="form-checkbox rounded" id="checkbox-signin"/>
                        <label class="ms-2" for="checkbox-signin">J'accepte les <a target='_blank' href="https://cartevirtuelle.shop/condition-generale-de-vente/" class="text-[#3063a3]">termes et conditions</a></label>
                    </div>

                    <div class="flex items-center mb-4 text-black">
                        <input required type="checkbox" class="form-checkbox rounded" id="checkbox-age"/>
                        <label class="ms-2" for="checkbox-age">Je reconnais avoir au moins 18 ans</label>
                    </div>

                    <div>
                        <button type="submit" className="bg-black p-2 inline-block w-full">Inscription</button>
                    </div>

                    <p class="text-black mt-8">Avez vous un compte ?<Link to="/" class="font-medium ms-1 underline">Se connecter</Link></p>

                </form>

                <ToastContainer/>

            </section>

            {/* <section class="bg-[#3063a3] h-screen w-screen flex justify-center items-center">
                <div class="2xl:w-1/4 lg:w-1/3 md:w-1/2 w-full">
                    <div class="card overflow-hidden sm:rounded-md rounded-none">
                        <div class="px-6 py-8">
                            <a href="index.html" class="flex justify-center mb-8">
                                <img class="h-10" src={logo} alt=""/>
                            </a>

                            <form onSubmit={handleSubmit}>

                                <div class="mb-4">
                                    <label class="mb-2" for="LoggingName">Nom complet</label>
                                    <input required onInput={e => setName(e.target.value)} id="LoggingName" class="form-input" type="text" placeholder="Votre nom complet"/>
                                </div>

                                <div class="mb-4">
                                    <label class="mb-2" for="LoggingEmailAddress">Adresse email</label>
                                    <input required onInput={e => setEmail(e.target.value)} id="LoggingEmailAddress" class="form-input" type="email" placeholder="Votre adresse email"/>
                                </div>

                                <div class="mb-4">
                                    <label class="mb-2" for="loggingPassword">Mot de passe</label>
                                    <input required onInput={e => setPassword(e.target.value)} id="loggingPassword" class="form-input" type="password" placeholder="Choisissez un mot de passe"/>
                                </div>

                                <div class="flex items-center mb-4">
                                    <input required type="checkbox" class="form-checkbox rounded" id="checkbox-signin"/>
                                    <label class="ms-2" for="checkbox-signin">J'accepte les <a target='_blank' href="https://cartevirtuelle.shop/condition-generale-de-vente/" class="text-[#3063a3]">termes et conditions</a></label>
                                </div>

                                <div class="flex justify-center mb-3">
                                    <button class="btn w-full text-white bg-[#030e16]"> S'inscrire </button>
                                </div>

                            </form>

                        </div>
                    </div>

                    <p class="text-white text-center mt-8">Avez vous un compte ?<Link to="/" class="font-medium ms-1">Se connecter</Link></p>
                </div>
            </section> */}

        </>
    )
}