import React, { useContext, useEffect, useState } from "react";
import GlobalLoader from "../../../../components/Spinners/GlobalLoader";
import usersService from "../../../../services/users.service";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Normal({ctx})
{

    const {info,setInfo} = useContext(ctx)

    const [loading,setLoading] = useState(null)

    // Non social entries

    const [name,setName] = useState(null)

    const [phone,setPhone] = useState(null)

    const [address,setAddress] = useState(null)

    const handleSubmit = async e => {

        e.preventDefault()

        setLoading(true)

        let data = {
            user : JSON.parse(localStorage.getItem('vc_user')).id,
            name : name,
            phone : phone,
            address : address
        }

        // alert(JSON.stringify(data))

        let response = await usersService.updateProfileInfo(data)

        if(response == undefined)
        {
            toast.error('Service error')
        }
        else{
            await getProfileInfo()
            toast.success('Information mises à jour')
        }

        setLoading(false)

    }

    const getProfileInfo = async () => {

        let response = await usersService.getProfileInfo()

        if(response == undefined)
        {
            toast.error('Service error')
        }
        else{
            // alert(JSON.stringify(response))
            setInfo(response)
        }

    }

    useEffect(() => {

        const _name = info?.cname
        const _phone = info?.phone
        const _add = info?.address

        if(_name)
        {
            setName(_name)
        }
        if(_phone)
        {
            setPhone(_phone)
        }
        if(_add)
        {
            setAddress(_add)
        }

    },[])

    return (

        <React.Fragment>

            {loading && <GlobalLoader/>}

            <section className="px-4">

                {loading && <GlobalLoader/>}

                <ToastContainer/>

                <header className="text-lg font-extrabold mb-7 text-black">
                    Mettez à jour vos informations si nécessaire
                </header>

                <form onSubmit={handleSubmit}>

                    <div class="mb-4">
                        <label class="mb-2" for="LoggingName">Votre nom complet</label>
                        <input value={name} onInput={e => setName(e.target.value)} required id="LoggingName" class="w-full block my-2 placeholder:text-gray-700" type="text" placeholder="Votre nom complet"/>
                    </div>

                    <div class="mb-4">
                        <label class="mb-2" for="LoggingName">Votre numéro de téléphone</label>
                        <input value={phone} onInput={e => setPhone(e.target.value)} required id="LoggingName" class="w-full block my-2 placeholder:text-gray-700" type="text" placeholder="Votre numéro de téléphone"/>
                    </div>

                    <div class="mb-4">
                        <label class="mb-2" for="LoggingName">Votre adresse</label>
                        <input value={address} onInput={e => setAddress(e.target.value)} required id="LoggingName" class="w-full block my-2 placeholder:text-gray-700" type="text" placeholder="Votre adresse"/>
                    </div>

                    <div class="flex justify-center mb-3">
                        <button class="btn w-full text-white bg-[#030e16] p-2"> Mettre à jour </button>
                    </div>

                </form>

            </section>

        </React.Fragment>

    )

}