import React, { useContext, useEffect, useState } from "react";
import GlobalLoader from "../../../../components/Spinners/GlobalLoader";

import flogo from "../../../../images/icons8-facebook-48.png"
import inslogo from "../../../../images/icons8-instagram-48.png"
import linklogo from "../../../../images/icons8-linkedin-48.png"
import youlogo from "../../../../images/icons8-youtube-48.png"
import vimlogo from "../../../../images/icons8-vimeo-video-platform-free-video-viewing-services-platform-48.png"
import tiklogo from "../../../../images/icons8-tic-tac-48.png"
import twlogo from "../../../../images/icons8-twitter-48.png"
import pinlogo from "../../../../images/icons8-pinterest-48.png"
import usersService from "../../../../services/users.service";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobeAltIcon } from "@heroicons/react/24/outline";

export default function NormalPlus({ctx})
{

    const {info,setInfo} = useContext(ctx)

    const [loading,setLoading] = useState(null)

    const [website,setWebsite] = useState(null)

    const [facebook,setFacebook] = useState(null)

    const [instagram,setInstagram] = useState(null)

    const [twitter,setTwitter] = useState(null)

    const [tiktok,setTiktok] = useState(null)

    const [vimeo,setVimeo] = useState(null)

    const [linkedin,setLinkedIn] = useState(null)

    const [youtube,setYouTube] = useState(null)

    const [pinterest,setPinterest] = useState(null)

    // Non social entries

    const [name,setName] = useState(null)

    const [phone,setPhone] = useState(null)

    const [address,setAddress] = useState(null)

    const handleSubmit = async e => {

        e.preventDefault()

        setLoading(true)

        let data = {
            user : JSON.parse(localStorage.getItem('vc_user')).id,
            website : website,
            facebook : facebook,
            linkedin : linkedin,
            youtube : youtube,
            twitter : twitter,
            tiktok : tiktok,
            vimeo : vimeo,
            pinterest : pinterest,
            instagram : instagram,
            name : name,
            phone : phone,
            address : address
        }

        // alert(JSON.stringify(data))

        let response = await usersService.updateProfileInfo(data)

        if(response == undefined)
        {
            toast.error('Service error')
        }
        else{
            getProfileInfo()
            toast.success('Information mises à jour')
        }

        setLoading(false)

    }

    const getProfileInfo = async () => {

        let response = await usersService.getProfileInfo()

        if(response == undefined)
        {
            toast.error('Service error')
        }
        else{
            // alert(JSON.stringify(response))
            setInfo(response)
        }

    }

    useEffect(() => {

        const fblink = info?.socials?.filter(social => social.realmedia.name.toLowerCase() == 'facebook')?.map(social => social.link)[0]

        const ytlink = info?.socials?.filter(social => social.realmedia.name.toLowerCase() == 'youtube')?.map(social => social.link)[0]

        const tiklink = info?.socials?.filter(social => social.realmedia.name.toLowerCase() == 'tiktok')?.map(social => social.link)[0]

        const vimlink = info?.socials?.filter(social => social.realmedia.name.toLowerCase() == 'vimeo')?.map(social => social.link)[0]

        const link = info?.socials?.filter(social => social.realmedia.name.toLowerCase() == 'linkedin')?.map(social => social.link)[0]

        const instlink = info?.socials?.filter(social => social.realmedia.name.toLowerCase() == 'instagram')?.map(social => social.link)[0]

        const twilink = info?.socials?.filter(social => social.realmedia.name.toLowerCase() == 'twitter')?.map(social => social.link)[0]

        const pinlink = info?.socials?.filter(social => social.realmedia.name.toLowerCase() == 'pinterest')?.map(social => social.link)[0]

        const _website = info?.website?.link

        const _name = info?.cname
        const _phone = info?.phone
        const _add = info?.address

        if(_name)
        {
            setName(_name)
        }
        if(_phone)
        {
            setPhone(_phone)
        }
        if(_add)
        {
            setAddress(_add)
        }

        if(_website)
        {
            setWebsite(_website)
        }

        if(fblink)
        {
            setFacebook(fblink)
        }

        if(ytlink)
        {
            setYouTube(ytlink)
        }

        if(tiklink)
        {
            setTiktok(tiklink)
        }

        if(vimlink)
        {
            setVimeo(vimlink)
        }

        if(link)
        {
            setLinkedIn(link)
        }

        if(instlink)
        {
            setInstagram(instlink)
        }

        if(twilink)
        {
            setTwitter(twilink)
        }

        if(pinlink)
        {
            setPinterest(pinlink)
        }

    },[info])

    return (

        <React.Fragment>

            {loading && <GlobalLoader/>}

            <section className="px-4">

                {loading && <GlobalLoader/>}

                <ToastContainer/>

                <header className="text-lg font-extrabold mb-7 text-black">
                    Mettez à jour vos informations si nécessaire
                </header>

                <form onSubmit={handleSubmit}>

                    <div class="mb-4">
                        <label class="mb-2" for="LoggingName">Votre nom complet</label>
                        <input value={name} onChange={e => setName(e.target.value)} required id="LoggingName" class="w-full block my-2 placeholder:text-gray-700" type="text" placeholder="Votre nom complet"/>
                    </div>

                    <div class="mb-4">
                        <label class="mb-2" for="LoggingName">Votre numéro de téléphone</label>
                        <input value={phone} onChange={e => setPhone(e.target.value)} required id="LoggingName" class="w-full block my-2 placeholder:text-gray-700" type="text" placeholder="Votre numéro de téléphone"/>
                    </div>

                    <div class="mb-4">
                        <label class="mb-2" for="LoggingName">Votre adresse</label>
                        <input value={address} onChange={e => setAddress(e.target.value)} required id="LoggingName" class="w-full block my-2 placeholder:text-gray-700" type="text" placeholder="Votre adresse"/>
                    </div>

                    <header className="">Lien vers votre site web</header>

                    <div class="flex my-5">
                        <div class="flex cursor-pointer items-center justify-center border border-[#bcc2c9] bg-[#ebf2fa] px-3 font-semibold ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md">
                            <GlobeAltIcon className="h-6"/>
                        </div>
                        <input value={website} id="carte_field" type="text" onChange={e => setWebsite(e.target.value)} placeholder="Lien vers votre site web" class="ltr:rounded-l-none focus:outline-none focus:border-none w-full rtl:rounded-r-none"/>
                    </div>

                    <header className="">Vos réseaux sociaux</header>

                    <div class="flex my-2">
                        <div class="flex cursor-pointer items-center justify-center border border-[#bcc2c9] bg-[#ebf2fa] px-3 font-semibold ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md">
                            <img src={flogo} alt="" className="h-6"/>
                        </div>
                        <input value={facebook} id="carte_field" type="text" onChange={e => setFacebook(e.target.value)} placeholder="Lien vers votre compte Facebook" class="ltr:rounded-l-none focus:outline-none focus:border-none w-full rtl:rounded-r-none"/>
                    </div>

                    <div class="flex my-2">
                        <div class="flex cursor-pointer items-center justify-center border border-[#bcc2c9] bg-[#ebf2fa] px-3 font-semibold ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md">
                            <img src={inslogo} alt="" className="h-6"/>
                        </div>
                        <input value={instagram} id="carte_field" type="text" onChange={e => setInstagram(e.target.value)} placeholder="Lien vers votre compte Instagram" class="ltr:rounded-l-none focus:outline-none focus:border-none w-full rtl:rounded-r-none"/>
                    </div>

                    <div class="flex my-2">
                        <div class="flex cursor-pointer items-center justify-center border border-[#bcc2c9] bg-[#ebf2fa] px-3 font-semibold ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md">
                            <img src={twlogo} alt="" className="h-6"/>
                        </div>
                        <input value={twitter} id="carte_field" type="text" onChange={e => setTwitter(e.target.value)} placeholder="Lien vers votre compte Twitter" class="ltr:rounded-l-none focus:outline-none focus:border-none w-full rtl:rounded-r-none"/>
                    </div>

                    <div class="flex my-2">
                        <div class="flex cursor-pointer items-center justify-center border border-[#bcc2c9] bg-[#ebf2fa] px-3 font-semibold ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md">
                            <img src={tiklogo} alt="" className="h-6"/>
                        </div>
                        <input value={tiktok} id="carte_field" type="text" onChange={e => setTiktok(e.target.value)} placeholder="Lien vers votre compte TikTok" class="ltr:rounded-l-none focus:outline-none focus:border-none w-full rtl:rounded-r-none"/>
                    </div>

                    <div class="flex my-2">
                        <div class="flex cursor-pointer items-center justify-center border border-[#bcc2c9] bg-[#ebf2fa] px-3 font-semibold ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md">
                            <img src={youlogo} alt="" className="h-6"/>
                        </div>
                        <input value={youtube} id="carte_field" type="text" onChange={e => setYouTube(e.target.value)} placeholder="Lien vers votre compte YouTube" class="ltr:rounded-l-none focus:outline-none focus:border-none w-full rtl:rounded-r-none"/>
                    </div>

                    <div class="flex my-2">
                        <div class="flex cursor-pointer items-center justify-center border border-[#bcc2c9] bg-[#ebf2fa] px-3 font-semibold ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md">
                            <img src={pinlogo} alt="" className="h-6"/>
                        </div>
                        <input value={pinterest} id="carte_field" type="text" onChange={e => setPinterest(e.target.value)} placeholder="Lien vers votre compte Pinterest" class="ltr:rounded-l-none focus:outline-none focus:border-none w-full rtl:rounded-r-none"/>
                    </div>

                    <div class="flex my-2">
                        <div class="flex cursor-pointer items-center justify-center border border-[#bcc2c9] bg-[#ebf2fa] px-3 font-semibold ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md">
                            <img src={linklogo} alt="" className="h-6"/>
                        </div>
                        <input value={linkedin} id="carte_field" type="text" onChange={e => setLinkedIn(e.target.value)} placeholder="Lien vers votre compte LinkedIn" class="ltr:rounded-l-none focus:outline-none focus:border-none w-full rtl:rounded-r-none"/>
                    </div>

                    <div class="flex my-2">
                        <div class="flex cursor-pointer items-center justify-center border border-[#bcc2c9] bg-[#ebf2fa] px-3 font-semibold ltr:rounded-l-md ltr:border-r-0 rtl:rounded-r-md">
                            <img src={vimlogo} alt="" className="h-6"/>
                        </div>
                        <input value={vimeo} id="carte_field" type="text" onChange={e => setVimeo(e.target.value)} placeholder="Lien vers votre compte Vimeo" class="ltr:rounded-l-none focus:outline-none focus:border-none w-full rtl:rounded-r-none"/>
                    </div>

                    <div class="flex justify-center mb-3">
                        <button class="btn w-full text-white bg-[#030e16] p-2"> Mettre à jour </button>
                    </div>

                </form>

            </section>

        </React.Fragment>

    )

}