import React, { useState } from "react";
import GlobalLoader from "../../../../components/Spinners/GlobalLoader";

export default function FillForFirst()
{

    const [loading,setLoading] = useState(false)

    const user = JSON.parse(localStorage.getItem('vc_user'));

    const [cname,setCname] = useState(null)

    const [phone,setPhone] = useState(null)

    const [pic,setPic] = useState(null)

    const handleSubmit = async e => {

        e.preventDefault()

    }

    return (
        <React.Fragment>
            
                <section className="">

                    {loading && <GlobalLoader/>}

                    <header className="text-lg font-extrabold mb-7 text-black">
                        Vérifiez et remplissez vos informations avant la mise en ligne de votre carte virtuelle
                    </header>

                    <form onSubmit={handleSubmit}>

                        <div class="mb-4">
                            <label class="mb-2" for="LoggingEmailAddress">Votre photo actuelle</label>
                            <div className="">
                                <img src={user.pic} alt="user image" className="h-14 w-14"/>
                            </div>
                        </div>

                        <div class="mb-4">
                            <label class="mb-2" for="LoggingEmailAddress">Ajouter une nouvelle photo <span className="text-red-600 text-xs">(facultatif)</span></label>
                            <input required onInput={e => setPic(e.target.files[0])} id="LoggingEmailAddress" class="form-input" type="file"/>
                        </div>

                        <div class="flex justify-center mb-3">
                            <button class="btn w-full text-white bg-[#030e16]"> Valider </button>
                        </div>

                    </form>

                </section>

        </React.Fragment>
    )

}