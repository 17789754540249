import React, { useEffect, useState } from "react";
import adminService from "../../../services/admin.service";

import { UserCircleIcon, CurrencyEuroIcon, LinkIcon, BoltIcon, InboxStackIcon, TrashIcon } from '@heroicons/react/24/outline'
import usersService from "../../../services/users.service";
import { useNavigate } from "react-router-dom";
import GlobalLoader from "../../../components/Spinners/GlobalLoader";
import OnpageLoader from "../../../components/Spinners/OnpageLoader";

export default function IndexAdmin()
{

    const [userCount,setUserCount] = useState(0)

    const [onlineProfilesCount,setOnlineProfilesCount] = useState(1)

    const [revenue,setRevenue] = useState(0)

    const [transactions,setTransactions] = useState([])

    const [loading,setLoading] = useState(true)

    const navigate = useNavigate()

    const get_user_count = async () => {
        
        setLoading(true)

        let response = await adminService.userCount()

        if(response == undefined)
        {
            alert('User count service error')
        }
        else{
            setUserCount(response)
        }

    }

    const get_online_profiles_count = async () => {

        let response = await adminService.onlineProfilesCount()

        if(response == undefined)
        {
            alert('Profile count service error')
        }
        else{
            setOnlineProfilesCount(response)
        }

    }

    const get_revenues = async () => {

        let response = await adminService.paymentSummary()

        if(response == undefined)
        {
            alert('Payment service error')
        }
        else{
            setRevenue(response.revenues)
        }

    }

    const get_latest_transactions = async () => {

        let response = await adminService.paymentSummary()

        if(response == undefined)
        {
            alert('Payment service error')
        }
        else{
            setTransactions(response.payments)
        }

        setLoading(false)

    }

    const deleteAccount = async e => {

        if(window.confirm('Cette action est irréversible, voulez vous poursuivre ?'))
        {

            // Handle account deletion action here

            setLoading(true)

            let data = {
                user_id : JSON.parse(localStorage.getItem('vc_user')).id
            }

            let response = await usersService.deleteAccount(data)

            if(response)
            {
                // Redirect the user to the login page
                navigate('/')
            }
            else{
                alert('Erreur lors de la suppression')
            }
            
            setLoading(false)

        }

    }

    useEffect(() => {

        get_user_count()

        get_online_profiles_count()

        get_revenues()

        get_latest_transactions()

    },[])

    return (
        
        <React.Fragment>
        

                <div className="flex items-center my-6 p-5 text-slate-400 shadow-current shadow-lg">
                    <BoltIcon className="h-6 text-slate-900"/>
                    <h4 className="text-slate-900 text-lg font-bold ml-2">Tableau de bord</h4>
                </div>

                <div className="grid xl:grid-cols-3 md:grid-cols-2 gap-6 mb-6">

                    <div className="col-xl-3 col-md-6">
                        <div className="shadow-lg text-slate-400 shadow-current">
                            <div className="p-5">
                                <UserCircleIcon className="h-7 text-blue-700 bg-blue-200 rounded-full"/>
                                <h6 className="font-extrabold  text-sm my-1 uppercase text-slate-800">Utilisateurs</h6>
                                <h3 className="text-xl mb-3" data-plugin="counterup">
                                    {loading ? <OnpageLoader/> : userCount}
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6">
                        <div className="shadow-lg text-slate-400 shadow-current">
                            <div className="p-5">
                                <CurrencyEuroIcon className="h-7 text-red-700 bg-red-200 rounded-full"/>
                                <h6 className="font-extrabold  text-sm my-1 uppercase text-slate-800">Revenues</h6>
                                <h3 className="text-xl mb-3">€<span>
                                {loading ? <OnpageLoader/> : revenue}
                                </span></h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-md-6">
                        <div className="shadow-lg text-slate-400 shadow-current">
                            <div className="p-5">
                                <LinkIcon className="h-7 text-green-700 bg-green-200 rounded-full"/>
                                <h6 className="font-extrabold  text-sm my-1 uppercase text-slate-800">Profiles en ligne</h6>
                                <h3 className="text-xl mb-3">{loading ? <OnpageLoader/> : onlineProfilesCount}</h3>
                            </div>
                        </div>
                    </div>

                </div>

                {/* <div className="grid lg:grid-cols-1 gap-6 mb-6">
                    
                    <div className="card">
                        <div className="p-5">
                            <div className="flex justify-between items-center">
                                <h4 className="card-title mb-4">Statistiques</h4>

                                <div className="relative">
                                    <button className="text-lg text-gray-600 p-2" data-fc-placement="left-start" data-fc-type="dropdown" type="button">
                                        <i className="mdi mdi-dots-vertical"></i>
                                    </button>

                                    <div className="hidden z-10 bg-white w-44 shadow rounded border p-2 transition-all duration-300 fc-dropdown-open:translate-y-0 translate-y-3">
                                        <a className="flex items-center py-1.5 px-3.5 rounded text-sm transition-all duration-300 bg-transparent text-gray-800 hover:bg-stone-100" href="javascript:void(0)">
                                            Action
                                        </a>
                                        <a className="flex items-center py-1.5 px-3.5 rounded text-sm transition-all duration-300 bg-transparent text-gray-800 hover:bg-gray-100" href="javascript:void(0)">
                                            Another action
                                        </a>
                                        <a className="flex items-center py-1.5 px-3.5 rounded text-sm transition-all duration-300 bg-transparent text-gray-800 hover:bg-gray-100" href="javascript:void(0)">
                                            Something else here
                                        </a>
                                    </div>
                                </div>
                            </div>


                            <div id="morris-bar-example" className="morris-chart" style={{height: '260px'}}></div>

                        </div>
                    </div>

                </div> */}

                {loading ? <OnpageLoader/> : 

                    <div className="grid xl:grid-cols-1 gap-6">

                        <div className="card overflow-hidden">
                            
                            <div className="flex items-center my-6 p-4 text-slate-400 shadow-current shadow-sm">
                                <InboxStackIcon className="h-6 text-slate-900"/>
                                <h4 className="text-slate-900 text-lg font-bold ml-2">Transactions</h4>
                            </div>

                            <div className="overflow-x-auto">
                                <div className="min-w-full inline-block align-middle whitespace-nowrap">
                                    <div className="overflow-hidden">
                                        <table className="min-w-full">
                                            <thead className="bg-light/40 border-b border-gray-200">
                                                <tr>
                                                    <th className="px-6 py-3 text-start">Plan</th>
                                                    <th className="px-6 py-3 text-start">Utilisateur</th>
                                                    <th className="px-6 py-3 text-start">Système de paiement</th>
                                                    <th className="px-6 py-3 text-start">Montant</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    transactions.length > 0 ? 

                                                    transactions.map(transaction => (

                                                        <tr className="border-b border-gray-200">
                                                            <td className="px-6 py-6">{transaction.plan.name}</td>
                                                            <td className="px-6 py-6">{transaction.user.name}</td>
                                                            <td className="px-6 py-6">
                                                                <span className="px-2 py-1 bg-success/10 text-success text-xs rounded">Stripe</span>
                                                            </td>
                                                            <td className="px-6 py-6">€{transaction.amount}</td>
                                                        </tr>

                                                    ))
                                                    : 

                                                    <tr className="border-b border-gray-200">
                                                        <td className="px-6 py-3">Aucune transaction pour le moment</td>
                                                    </tr>

                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>                
                
                }

                {/* <div className="my-4">

                    <button onClick={deleteAccount} className="bg-red-800 rounded text-white font-bold w-full flex items-center justify-center p-4">
                        <TrashIcon className="w-8"/> 
                        <span>Supprimer le compte</span>
                    </button>

                </div> */}

        </React.Fragment>

    )
}