import React from "react";
import axios from "axios";
import env from "../env"

export default class sendRequest{

    static postData = async (data,url,type = null) => {

        try {

            var response = null

            if(!localStorage.getItem('vc_access_token'))
            {

                response = await axios.post(`${env.API_BASE}${url}`,data)

            }
            else{

                response = await axios.post(`${env.API_BASE}${url}`,data,{
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('vc_access_token')}`,
                        'Content-Type': type ? type : 'application/json',
                      },
                })

            }

            return response;
            
        } catch (error) {
            return error.response
        }

    }

    static getData = async (url) => {

        try {

            var response = null

            

            if(!localStorage.getItem('vc_access_token'))
            {

                response = await axios.get(`${env.API_BASE}${url}`)

            }
            else{

                response = await axios.get(`${env.API_BASE}${url}`,{
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('vc_access_token')}`,
                        'Content-Type': 'application/json',
                      },
                })

            }

            return response;
            
        } catch (error) {
            return error.response
        }

    }

    // return {postData,getData}

}