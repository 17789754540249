import React from "react";
import "../../stylesheets/onpage.css"

export default function OnpageLoader()
{

    return (

        <span class="loader"></span>

    )

}