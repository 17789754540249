import { UserIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import React, { useContext, useEffect, useState } from "react";
import usersService from "../services/users.service";
import { Link, useNavigate } from "react-router-dom";
import GlobalLoader from "./Spinners/GlobalLoader";

export default function AddContact({infoctx})
{

    const {info,setInfo} = useContext(infoctx)

    const navigate = useNavigate()

    const [local,setLocal] = useState(false)

    const [hasContact,setHasContact] = useState(false)

    const [connected,setConnected] = useState(false)

    const [loading,setLoading] = useState(false)

    const addToContact = async e => {

        setLoading(true)

        e.preventDefault()

        let contact = e.target.closest('button').getAttribute('data')

        // alert(contact)

        if(contact != null)
        {

            // All the contact adding logic here

            let data = {
                contact : contact,
                user_id : JSON.parse(localStorage.getItem('vc_user')).id
            }

            let response = await usersService.addContact(data)

            if(!response)
            {
                console.log('Error creating contact')
            }
            else{
                navigate('/client/contacts')
            }

        }

        setLoading(false)

    }

    const _hasContact = async () => {

        if(info)
        {

            let data = {
                contact : info.id,
                user_id : JSON.parse(localStorage.getItem('vc_user')).id
            }

            // alert(JSON.stringify(info))
    
            let response = await usersService.hasContact(data)
    
            if(response == undefined)
            {
                console.log('Error checking contact')
            }
            else{
    
                // alert(response)
    
                setHasContact(response)
    
            }

        }

    }

    useEffect(() => {

        if(localStorage.getItem('vc_user')) setLocal(true)

        usersService.isOnline()
        .then(res => {

            if(res != undefined)
            {
                setConnected(res)
            }

        })

        _hasContact()

    },[info])

    return (

        <>

            {loading && <GlobalLoader/>}

            {
                local && connected && !hasContact ?
                <div className="px-5 pb-3">

                    <button data={info.id} onClick={addToContact} className="bg-blue-700 text-white font-bold p-2">
                        <UserPlusIcon className="h-5 inline-block"/>
                        <span>Ajouter aux contacts</span>
                    </button>

                </div> : <></>
            }

            {
                !connected &&
                <div className="px-5 pb-3">
                    <Link to='/' className="text-blue-700 underline font-bold p-2">
                        <UserIcon className="h-5 inline-block"/>
                        <span>Connectez vous pour l'ajouter à vos contacts</span>
                    </Link>
                </div>
            }

            {/* {

                JSON.parse(localStorage.getItem('vc_user')).email != info.email && 
                        <div className="px-5 pb-3">

                            <button data={info.id} onClick={addToContact} className="bg-blue-700 text-white font-bold p-2">
                                <UserPlusIcon className="h-5 inline-block"/>
                                <span>Ajouter aux contacts</span>
                            </button>

                        </div>

            } */}
            
        </>

    )

}