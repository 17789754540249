import React, { useEffect, useState } from "react";
import logo from "../../../images/logo.png"
import stripelogo from "../../../images/stripeicon.png"
import GlobalLoader from "../../../components/Spinners/GlobalLoader";
import paymentService from "../../../services/payment.service";

export default function PaymentMethods()
{

    const [loading,setLoading] = useState(false)

    const create_stripe_payment = async () => {

        /*
        
            -Create payment then redirect to Stripe checkout page

            -On backend : create step and settle it

            -Then redirect to the app
        
        */

        setLoading(true)

        let data = JSON.parse(localStorage.getItem('vc_payment_data'))

        let response = await paymentService.createStripePayment(data)

        if(response != undefined)
        {
            return window.location.replace(response)
        }

        setLoading(false)

    }

    useEffect(() => {

    },[])

    return (

        <section class="bg-[#3063a3] h-screen w-screen flex justify-center items-center px-4">
            {loading && <GlobalLoader/>}
            
            <div class="bg-white overflow-hidden sm:rounded-md rounded-none">
                <div class="px-6 py-8">
                    <a href="index.html" class="flex justify-center mb-8">
                        <img class="h-6" src={logo} alt=""/>
                    </a>

                    {/* <div class="w-1/2 mx-auto block my-8">
                        <img src={logo404} title="maintenance.svg"/>
                    </div> */}

                    <h3 class="text-dark mb-4 mt-6 text-center sm:text-2xl text-xl font-extrabold">Effectuez votre paiement via :</h3>

                    <div class="flex justify-center mb-3">
                        <button onClick={create_stripe_payment} class="w-full text-[#030e16] bg-white border-2 border-[#030e16] flex items-center justify-center p-2">
                            <img src={stripelogo} alt="Stripe" className="h-6"/>
                            <span className="ml-5 font-extrabold text-lg">Stripe</span>
                        </button>
                    </div>

                </div>
            </div>

        </section>

    )

}