import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import logo from "../../images/logo.png"
import { Link } from "react-router-dom";


export default function RegisterSuccess()
{

    const [noSuccessRedirect,setNoSuccessRedirect] = useState(false)

    useEffect(() => {

        if(!localStorage.getItem('vc_register_success'))
        {
            setNoSuccessRedirect(true)
        }

    },[])

    return (

        <section class="bg-[#3063a3] h-screen w-screen flex justify-center items-center px-4">
            {noSuccessRedirect == true && <Navigate to="/"/>}
            
            <div class="lg:w-5/12 bg-white overflow-hidden rounded-none">
                    <div class="px-6 py-8">
                        <a href="index.html" class="flex justify-center mb-8">
                            <img class="h-6" src={logo} alt=""/>
                        </a>

                        {/* <div class="w-1/2 mx-auto block my-8">
                            <img src={logo404} title="maintenance.svg"/>
                        </div> */}

                        <h3 class="text-dark mb-4 mt-6 text-center text-3xl">Compte validé</h3>

                        <p class="text-dark/75 mb-8 mx-auto text-base text-center">Compte validé avec succès, veuillez vous connecter à présent</p>

                        <div class="flex justify-center">
                            <Link to="/" class="bg-[#3063a3] p-2 text-white bg-primary"> Connectez vous </Link>
                        </div>

                    </div>
                </div>

        </section>

    )

}