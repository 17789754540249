import React, { useEffect, useState } from "react";
import GlobalLoader from "../../../components/Spinners/GlobalLoader";
import adminService from "../../../services/admin.service";

import { TrashIcon, GlobeAltIcon } from '@heroicons/react/24/outline'
import OnpageLoader from "../../../components/Spinners/OnpageLoader";

export default function Networks()
{

    const [name,setName] = useState(null)

    const [pic,setPic] = useState(null)

    const [media,setMedia] = useState([])

    const [loading,setLoading] = useState(false)

    const [onLoad,setOnLoad] = useState(false)
    
    const get_media = async () => {

        setOnLoad(true)

        let response = await adminService.getMedia()

        if(response == undefined)
        {
            setOnLoad(false)
            alert('Admin service error while fetching medias')
        }
        else{
            setOnLoad(false)
            setMedia(response)
        }

    }

    const updateMedia = async e => {

        e.preventDefault()

    }

    const handleNetworkCreation = async e => {

        e.preventDefault()

        setLoading(true)

        let data = {
            name : name,
            pic : pic
        }

        let response = await adminService.createMedia(data)

        if(response == undefined)
        {
            alert('Admin service error while creating media')
        }
        else{
            get_media()
        }

        setLoading(false)

    }

    const delete_media = async e => {

        e.preventDefault()

        setLoading(true)

        let data = {media : e.target['media'].value}

        let response = await adminService.deleteMedia(data)

        if(response == undefined)
        {
            alert('Admin service error while deleting media')
        }
        else{
            get_media()
        }

        setLoading(false)

    }

    useEffect(() => {

        get_media()

    },[])

    return (

        <>

                    <div class="card">
                        {loading && <GlobalLoader/>}
                        
                        {onLoad ? <OnpageLoader/> : 
                        
                            <div class="p-6">

                                <div className="flex items-center mb-6 p-5 text-slate-400 shadow-current shadow-lg">
                                    <GlobeAltIcon className="h-6 text-slate-900"/>
                                    <h4 className="text-slate-900 text-lg font-bold ml-2">Les réseaux disponibles</h4>
                                </div>

                                <div  class="rounded border divide-y grid grid-cols-2 border-gray-200 divide-slate-900/10">

                                    {
                                        media.length > 0 ? 
                                        media.map(only => (

                                            <>
                                                {/* <button data-fc-type="collapse" class="fc-collapse-open:text-primary p-3 inline-flex items-center justify-between gap-x-3 w-full font-semibold text-left text-gray-800 transition hover:text-gray-500">
                                                    {only.name}
                                                    <i class="bx bx-chevron-up fc-collapse-open:rotate-180 ms-auto transition-all text-xl"></i>
                                                </button> */}
                                                <div class="overflow-hidden">
                                                    <p class="p-3 text-gray-800">

                                                        {/* Add update and display components here */}

                                                        <div className="flex items-center">
                                                            <div className="">
                                                                <img src={only.pic} className="w-6" alt={only.name}/>
                                                            </div>
                                                            <div className="">
                                                                {only.name}
                                                            </div>
                                                            <div className="">

                                                                <form onSubmit={delete_media}>

                                                                    <input type="hidden" name="media" value={only.id}/>

                                                                    <button className="bg-red-500 text-white flex items-center justify-center p-1 rounded ml-2">
                                                                        <TrashIcon className="h-4"/>
                                                                    </button>

                                                                </form>

                                                            </div>
                                                        </div>

                                                    </p>
                                                </div>
                                            </>

                                        )) : 
                                        <div className="">Aucun réseau disponible pour le moment</div>
                                    }

                                </div>
                            </div>
                        
                        }

                    </div>

                    <div class="card mt-10">
                        <div class="p-6">
                            <h4 class="font-bold mb-4">Ajouter un réseau</h4>

                            {/* Add plan creation form here */}

                            <form onSubmit={handleNetworkCreation}>

                                <div class="mb-4">
                                    <label class="mb-2" for="LoggingEmailAddress">Dénomination du réseau</label>
                                    <input required onInput={e => setName(e.target.value)} id="LoggingEmailAddress" class="w-full block my-2 placeholder:text-gray-700" type="text" placeholder="Dénomination du réseau"/>
                                </div>

                                <div class="mb-4">
                                    <label class="mb-2" for="LoggingEmailAddress">Logo du réseau</label>
                                    <input required onInput={e => setPic(e.target.files[0])} type="file" id="LoggingEmailAddress" class="w-full block my-2 placeholder:text-gray-700 border p-2 border-black"/>
                                </div>

                                <div class="flex justify-center mb-3">
                                    <button class="btn w-full text-white bg-[#030e16] p-2"> Ajouter </button>
                                </div>

                            </form>
                            
                        </div>
                    </div>

        </>

    )

}