import React, { useEffect, useState } from "react";
import logo from "../../images/logo.png"
import { Link, Navigate } from "react-router-dom";
import titleService from "../../services/title.service";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import usersService from "../../services/users.service";

import GlobalLoader from "../../components/Spinners/GlobalLoader";

export default function PasswordRecovery()
{

    const [loading,setLoading] = useState(false)

    const [email,setEmail] = useState(null)

    const [shouldRedirectToConfirmation,setShouldRedirectToConfirmation] = useState(false)

    const handleSubmit = async e => {

        e.preventDefault()

        setLoading(true)

        if(email == null)
        {
            toast.error('Veuillez renseigner votre email')
        }
        else{

            let response = await usersService.sendVerificationEmail(email)

            if(response == true)
            {
                // Create a localStorage to store the email then redirect
                localStorage.setItem('vc_recuperation_email',email)
                setShouldRedirectToConfirmation(true)
            }
            else{
                toast.error('Utilisateur inexistant')
            }

        }

        setLoading(false)

    }

    useEffect(() => {

        titleService.passwordRecoveryTitle()

        if(localStorage.getItem('vc_should_change_password'))
        {
            localStorage.removeItem('vc_should_change_password')
        }

    },[])

    return (

        <section className="bg-[#3063a3] h-screen w-screen flex justify-center items-center">

            {loading && <GlobalLoader/>}
            {shouldRedirectToConfirmation && <Navigate to="/verification-email"/>}
            
            <form onSubmit={handleSubmit} className="bg-white text-white lg:w-4/12 md:w-6/12 lg:p-6 md:p-6 sm:w-7/12 sm:p-4 w-11/12 p-5">

                {loading && <GlobalLoader/>}

                <div class="flex justify-center mb-8">
                    <img class="h-10" src={logo} alt=""/>
                </div>

                <p class="text-center lg:w-3/4 mx-auto mb-3 text-black">Entrez votre email, nous vous enverrons un code pour récuperer votre mot de passe</p>

                <div className="pb-4">
                    <label htmlFor="username" className="block py-4 text-black">Votre email</label>
                    <input required type="email" className="bg-gray-100 p-2 w-full text-black" id="username" onChange={(e) => setEmail(e.target.value)} placeholder="Votre email"/>
                </div>

                <div>
                    <button type="submit" className="bg-black p-2 inline-block w-full">Réinitialiser le mot de passe </button>
                </div>

                <p class="text-black text-center mt-8">Avez vous un compte ?<Link to="/" class="font-medium ms-1 underline text-blue-600">Se connecter</Link></p>

            </form>

            <ToastContainer/>

        </section>

    )

}